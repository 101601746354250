export enum ScreenTypes {
  SignIn = 'sign-in',
  SignUp = 'sign-up',
  ResendConfirmLink = 'resend-confirmation-link',
  ResetPassword = 'reset-password',
  ConfirmationCodeSent = 'confirmation-code-sent',
  ConfirmationSuccess = 'confirmation-success',
  Portal = 'portal',
  SuccessSSO = 'success-sso',
  AccountLinked = 'account-linked',
  App = 'app',
}
