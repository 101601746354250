import './PortalNotFound.css';

export function PortalNotFound() {
  return (
    <div className='whitebox-centered'>
      <div className='whitebox-popup'>
        <div className='whitebox_Title'>
          <h2>Portal not found</h2>
        </div>
        <p className='centered-text-nospacing'>
          Unfortunately, this portal has been deleted or you do not have access
          to it.
        </p>
      </div>
    </div>
  );
}
