import { Button, Checkbox, Form, Modal } from 'antd';
import { ButtonsType } from '../../../shared/enums/buttons-type.enum';
import React, { useEffect, useState } from 'react';

interface IProps {
  source: 'signUp' | 'signIn';
  setVisibleTermsModal: any;
  visible: boolean;
  url: string;
}

export function Terms(props: IProps) {
  const [newsEnabled, setNewsEnabled] = useState<boolean>(false);
  const [trackingEnabled, setTrackingEnabled] = useState<boolean>(false);

  let identityProvider: string = null;

  if (props?.url) {
    identityProvider = new URLSearchParams(new URL(props?.url).search).get(
      'identity_provider',
    );
    localStorage.setItem('SSOProvider', identityProvider);
  }

  const privacyPolicy: string | undefined =
    localStorage.getItem('PrivacyPolicy');

  useEffect(() => {
    if (privacyPolicy && !props.visible) return;
    localStorage.setItem('TrackingEnabled', String(newsEnabled));
    localStorage.setItem('NewsEnabled', String(trackingEnabled));
  }, [trackingEnabled, newsEnabled]);

  if (props.visible && privacyPolicy) {
    window.location.href = props.url;
    return <div></div>;
  }

  return (
    <Modal
      footer={[]}
      visible={props.visible}
      className='Terms-wrapper'
      onCancel={() => {
        props.setVisibleTermsModal(false);
      }}
    >
      <div className='whitebox-popup'>
        <h2 style={{ textAlign: 'center', marginBottom: 30 }}>
          {props.source === 'signUp' ? 'Sign up terms' : 'Sign in terms'}
        </h2>
        <Form>
          {props.source === 'signUp' && (
            <div>
              <Form.Item
                className='small-font-size'
                name='trackingEnabled'
                valuePropName='checked'
              >
                <Checkbox
                  onChange={(e) => setTrackingEnabled(e.target.checked)}
                >
                  I agree to help improve VISPA:&nbsp;
                  <a
                    href='https://www.vispa.io/privacy'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Privacy Policy
                  </a>
                  .
                </Checkbox>
              </Form.Item>

              <Form.Item
                className='small-font-size'
                name='newsEnabled'
                valuePropName='checked'
              >
                <Checkbox onChange={(e) => setNewsEnabled(e.target.checked)}>
                  I agree to receive news and updates.
                </Checkbox>
              </Form.Item>
            </div>
          )}

          <p style={{ fontSize: 14 }}>
            Our&nbsp;
            <a
              href='https://app.hubspot.com/documents/20393810/view/266024796?accessId=3a9a80'
              target='_blank'
              rel='noreferrer'
            >
              Terms of Use&nbsp;
            </a>
            apply. Take note of our&nbsp;
            <a
              href='https://app.hubspot.com/documents/20393810/view/428277639?accessId=cf5e03'
              target='_blank'
              rel='noreferrer'
            >
              Information on Right of Withdrawal&nbsp;
            </a>
            and our&nbsp;
            <a
              href='https://www.vispa.io/privacy'
              target='_blank'
              rel='noreferrer'
            >
              Privacy Policy.
            </a>
          </p>

          <Button
            type={ButtonsType.Primary}
            className='ant-btn-streched'
            onClick={() => {
              localStorage.setItem('PrivacyPolicy', 'true');
              props.setVisibleTermsModal(false);
            }}
          >
            <a href={props.url}>
              {props.source === 'signUp'
                ? 'Continue to sign up'
                : 'Continue to sign in'}
            </a>
          </Button>
        </Form>
      </div>
    </Modal>
  );
}
