import { gql } from '@apollo/client';

export const SCHEDULE_PROFILE_FOR_DELETE = gql`
  mutation ScheduleForDelete($userId: String!, $timestamp: timestamptz!) {
    update_Users(
      where: { Id: { _eq: $userId } }
      _set: { ScheduledForDeletion: $timestamp }
    ) {
      affected_rows
    }
  }
`;
