import logo from '../../../assets/images/VISPA_Logo_143x40.svg';
import React, { CSSProperties } from 'react';
import { useAppDispatch } from '../../../app/hooks';
import { hideSidebar } from '../../App/appSlice';
import { getUserData } from '../../Api/helpers/get-user-data';

const containerStyle = {
  margin: 'auto',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column' as const,
  height: '50vh',
};

const logoStyle = {
  height: 50,
  width: 180,
  margin: 'auto auto 60px',
};

const h1Style: CSSProperties = {
  fontSize: 32,
  textAlign: 'center',
  margin: '0 0 15px',
  marginBottom: 24,
};

const h2Style = {
  ...h1Style,
  fontWeight: 600,
};

const pStyle = {
  fontSize: 20,
  padding: 25,
};

export function SuccessSSO() {
  const dispatch = useAppDispatch();
  dispatch(hideSidebar(false));
  const user = getUserData();

  return (
    <div>
      <div style={containerStyle}>
        <img src={logo} style={logoStyle} alt='logo' />
        <div style={h1Style}>You're now logged in as</div>
        <div style={h2Style}>{user.attributes.email}</div>

        <p style={pStyle}>Just return to the app to get started!</p>
      </div>
    </div>
  );
}
