import { Button, Checkbox, Form, Input, Modal } from 'antd';
import React, { useState } from 'react';
import { ButtonsType } from '../../../shared/enums/buttons-type.enum';
import { ScreenTypes } from '../types/screen-types';
import { openNotificationWithIcon } from '../../../shared/helpers/notify';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { Auth } from 'aws-amplify';
import { PasswordInput } from '../../../components/PasswordInput/PasswordInput';
import { IconComponent } from '../../../components/Icon/Icon';
import { getErrorWithText } from '../../../shared/helpers/getErrorWithText';
import { useQuery } from '../../../shared/helpers/use-query';
import { emailRegex } from '../../../shared/regex/email-regex';
import GoogleSvg from '../../../assets/icons/google.svg';
import { APPLE_AUTH_LINK, GOOGLE_AUTH_LINK } from '../../../config/env';
import { Terms } from './Terms';

const ResetLinkText = {
  Initial: <span>Resend verification link</span>,
  Sent: (
    <span>
      {' '}
      <CheckCircleTwoTone twoToneColor='#52c41a' />
      &nbsp;Verification link sent
    </span>
  ),
};

export function SignUp(props) {
  const query = useQuery();

  const [visibleTermsModal, setVisibleTermsModal] = useState<boolean>(false);
  const [step, setStep] = useState(0);
  const [level, setLevel] = useState(0);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [newsEnabled, setNewsEnabled] = useState(false);
  const [trackingEnabled, setTrackingEnabled] = useState(false);
  const [termsVisible, setTermsVisible] = useState(false);
  const [termsUrl, setTermsUrl] = useState('');

  const [usernameAlreadyTakenError, setUsernameAlreadyTakenError] =
    useState(false);

  const [registrationLoading, setRegistrationLoading] = useState(false);
  const [resetLinkLoading, setResetLinkLoading] = useState(false);
  const [resetLinkText, setResetLinkText] = useState(ResetLinkText.Initial);
  const [resetLinkDisabled, setResetLinkDisabled] = useState(false);

  const queryEmail = query.get('email');

  const handleSignUp = async () => {
    if (level === 0) return;
    setRegistrationLoading(true);
    const email = username || queryEmail;
    try {
      const user = await Auth.signUp({
        username: email.toLowerCase(),
        password,
        attributes: {
          email: email.toLowerCase(),
          'custom:FirstName': firstName,
          'custom:LastName': lastName,
          'custom:TrackingEnabled': trackingEnabled.toString(),
          'custom:NewsEnabled': newsEnabled.toString(),
        },
      });

      if (user) {
        await openNotificationWithIcon({
          message:
            'We have sent you the verification link, please check your email including the spam folder.',
          type: 'success',
          duration: 0,
        });
        setStep(4);
      }
    } catch (e) {
      if (e.code === 'UsernameExistsException') {
        setUsernameAlreadyTakenError(true);
        await openNotificationWithIcon({ message: e.message, type: 'error' });
        setStep(1);
        return;
      }
      await openNotificationWithIcon({ message: e.message, type: 'error' });
    } finally {
      setRegistrationLoading(false);
    }
  };

  const handleResetLink = async () => {
    if (resetLinkLoading) return;
    setResetLinkLoading(true);
    try {
      await Auth.resendSignUp(username);
      setResetLinkText(ResetLinkText.Sent);
    } catch (e) {
      await openNotificationWithIcon({ message: e.message, type: 'error' });
    } finally {
      setResetLinkLoading(false);
      setResetLinkDisabled(true);
      setTimeout(() => {
        setResetLinkText(ResetLinkText.Initial);
        setResetLinkDisabled(false);
      }, 5000);
    }
  };

  const signUpButton = (
    <div className='bottom-text' style={{ marginTop: 30 }}>
      Already have an Account?
      <span
        onClick={() => props.setScreen(ScreenTypes.SignIn)}
        className='text-btn cursor-pointer'
      >
        {' '}
        Sign in
      </span>
    </div>
  );

  const signUpComponent = (
    <div>
      <div className='whitebox_Title'>
        <h2 style={{ marginBottom: 10 }}>Sign up</h2>
      </div>
      <p style={{ margin: 0 }} className='whitebox-popup-subhead'>
        No payment info needed
      </p>
    </div>
  );

  return (
    <div className='whitebox-popup' style={{ margin: '0 0 2rem 0' }}>
      {step === 0 && (
        <div>
          {signUpComponent}
          <div>
            <Button
              className='ant-btn-streched sso-buttons'
              onClick={() => {
                setTermsUrl(GOOGLE_AUTH_LINK);
                setTermsVisible(true);
              }}
            >
              <img
                src={GoogleSvg}
                style={{
                  fontSize: 20,
                  left: 10,
                  position: 'absolute',
                  width: 20,
                  top: 12,
                }}
              />
              Sign up with Google
            </Button>

            <Button
              className='ant-btn-streched sso-buttons'
              onClick={() => {
                setTermsUrl(APPLE_AUTH_LINK);
                setTermsVisible(true);
              }}
            >
              <IconComponent
                props={{
                  iconName: 'icon-Apple',
                  style: getStylesForContinueWithButton(),
                }}
              />
              Sign up with Apple
            </Button>
            <Button
              onClick={() => setVisibleTermsModal(true)}
              className='ant-btn-streched sso-buttons'
            >
              <IconComponent
                props={{
                  iconName: 'icon-Mail',
                  style: getStylesForContinueWithButton(),
                }}
              />
              Sign up with your email
            </Button>
          </div>
          {signUpButton}
        </div>
      )}

      {step === 1 && (
        <div>
          <div className='whitebox_Title'>
            {signUpComponent}
            <Form
              name='basic'
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              onFinish={() => setStep(2)}
              validateTrigger='submit'
              initialValues={{ username: queryEmail }}
            >
              <Form.Item
                label='Work Email'
                name='username'
                rules={[
                  {
                    required: true,
                    message: getErrorWithText('Enter your email address'),
                    whitespace: true,
                  },
                  {
                    type: 'email',
                    message: getErrorWithText('Enter a valid email address'),
                  },
                  {
                    pattern: new RegExp(emailRegex),
                    message: getErrorWithText(
                      'Sorry, only letters (A-z), number (0-9), and periods (.) are allowed',
                    ),
                  },
                ]}
              >
                <Input
                  style={{ borderColor: usernameAlreadyTakenError && 'red' }}
                  disabled={!!queryEmail}
                  defaultValue={queryEmail}
                  onChange={(e) => {
                    setUsernameAlreadyTakenError(false);
                    setUsername(e.target.value);
                  }}
                />
              </Form.Item>

              {usernameAlreadyTakenError &&
                getErrorWithText('Sorry, this email is already in use')}

              <div>
                <Button
                  htmlType='submit'
                  className='ant-btn-streched'
                  type={ButtonsType.Primary}
                >
                  Next
                </Button>
              </div>
            </Form>
            {signUpButton}
          </div>
        </div>
      )}

      {step === 2 && (
        <div>
          {signUpComponent}

          <Form
            name='basic'
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={() => setStep(3)}
            validateTrigger='submit'
          >
            <Form.Item
              label='First name'
              name='firstName'
              style={{ marginBottom: 10 }}
              rules={[
                {
                  required: true,
                  message: getErrorWithText('Enter your first name'),
                  whitespace: true,
                },
              ]}
            >
              <Input onChange={(e) => setFirstName(e.target.value)} />
            </Form.Item>
            <Form.Item
              label='Last name'
              name='lastName'
              style={{ marginBottom: 10 }}
              rules={[
                {
                  required: true,
                  message: getErrorWithText('Enter your last name'),
                  whitespace: true,
                },
              ]}
            >
              <Input onChange={(e) => setLastName(e.target.value)} />
            </Form.Item>
            <div>
              <Button
                htmlType='submit'
                className='ant-btn-streched'
                type={ButtonsType.Primary}
              >
                Next
              </Button>

              <Button
                type={ButtonsType.Link}
                className='ant-btn-streched'
                onClick={() => setStep(1)}
              >
                Back
              </Button>
            </div>
          </Form>
        </div>
      )}

      {step === 3 && (
        <div>
          {signUpComponent}
          <Form
            name='basic'
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={handleSignUp}
          >
            <Form.Item
              label='Password'
              name='password'
              style={{ marginBottom: 10 }}
              rules={[
                {
                  required: true,
                  message: getErrorWithText('Enter your password'),
                },
                {
                  required: true,
                  validator: async () => level >= 0 && Promise.resolve(),
                },
              ]}
            >
              <PasswordInput
                onChange={(e) => setPassword(e.target.value)}
                onLevelChange={setLevel}
              />
            </Form.Item>
            <Form.Item
              label='Confirm password'
              name='confirmPassword'
              dependencies={['password']}
              style={{ marginBottom: 10 }}
              rules={[
                {
                  required: true,
                  message: getErrorWithText('Please confirm your password!'),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      getErrorWithText('Confirm password does not match'),
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Button
              type={ButtonsType.Primary}
              htmlType='submit'
              className='ant-btn-streched'
              loading={registrationLoading}
            >
              Register
            </Button>
          </Form>
          <Button
            type={ButtonsType.Link}
            className='ant-btn-streched'
            onClick={() => setStep(2)}
          >
            Back
          </Button>
        </div>
      )}

      {step === 4 && (
        <div>
          <div className='whitebox_Title'>
            <h2>Verify your email</h2>
          </div>
          <p
            className='whitebox-popup-subhead'
            style={{ margin: 0, padding: 0, paddingBottom: 30 }}
          >
            Please check your email, we have sent you a verification link to the
            email <b>{username}.</b>
            <br />
            <br /> After verification you are
            <br />
            ready to go!
          </p>
          <div>
            <Button
              className='primary-text-button'
              style={{ margin: 0 }}
              disabled={resetLinkDisabled}
              loading={resetLinkLoading}
              type={ButtonsType.Link}
            >
              <span
                onClick={() => handleResetLink()}
                className='text-btn cursor-pointer'
              >
                {resetLinkText}
              </span>
            </Button>
          </div>
        </div>
      )}

      <Modal
        footer={[]}
        visible={visibleTermsModal}
        className='whitebox-centered'
        onCancel={() => {
          setVisibleTermsModal(false);
        }}
        style={{ top: '27%' }}
      >
        <div className='whitebox-popup'>
          <h2 style={{ textAlign: 'center', marginBottom: 30 }}>
            Sign up terms
          </h2>
          <Form>
            <Form.Item
              className='small-font-size'
              name='trackingEnabled'
              valuePropName='checked'
            >
              <Checkbox onChange={() => setTrackingEnabled(!trackingEnabled)}>
                I agree to help improve VISPA:&nbsp;
                <a
                  href='https://www.vispa.io/privacy'
                  target='_blank'
                  rel='noreferrer'
                >
                  Privacy Policy
                </a>
                .
              </Checkbox>
            </Form.Item>

            <Form.Item
              className='small-font-size'
              name='newsEnabled'
              valuePropName='checked'
            >
              <Checkbox onChange={() => setNewsEnabled(!newsEnabled)}>
                I agree to receive news and updates.
              </Checkbox>
            </Form.Item>

            <p style={{ fontSize: 14 }}>
              Our&nbsp;
              <a
                href='https://app.hubspot.com/documents/20393810/view/266024796?accessId=3a9a80'
                target='_blank'
                rel='noreferrer'
              >
                Terms of Use&nbsp;
              </a>
              apply. Take note of our&nbsp;
              <a
                href='https://app.hubspot.com/documents/20393810/view/428277639?accessId=cf5e03'
                target='_blank'
                rel='noreferrer'
              >
                Information on Right of Withdrawal&nbsp;
              </a>
              and our&nbsp;
              <a
                href='https://www.vispa.io/privacy'
                target='_blank'
                rel='noreferrer'
              >
                Privacy Policy.
              </a>
            </p>

            <Button
              type={ButtonsType.Primary}
              className='ant-btn-streched'
              onClick={() => {
                setVisibleTermsModal(false);
                setStep(1);
              }}
            >
              Continue to sign up
            </Button>
          </Form>
        </div>
      </Modal>

      <Terms
        visible={termsVisible}
        setVisibleTermsModal={setTermsVisible}
        url={termsUrl}
        source={'signUp'}
      />
    </div>
  );
}

const getStylesForContinueWithButton = () => {
  return { position: 'absolute', left: 10, top: 12, color: 'black' };
};
