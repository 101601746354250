import { Popover } from 'antd';

export function AvailableSoon({ componentToPassDown }) {
  return (
    <Popover
      placement='topLeft'
      content={<div>Available soon</div>}
      trigger='hover'
    >
      {componentToPassDown}
    </Popover>
  );
}
