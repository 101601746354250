import { cognitoConfig } from '../../../config/env';
import jwt_decode from 'jwt-decode';

export const getIdTokenSync = () => {
  const poolId = cognitoConfig().aws_user_pools_web_client_id;
  const userid = localStorage.getItem(
    'CognitoIdentityServiceProvider.' + poolId + '.LastAuthUser',
  );
  return localStorage.getItem(
    'CognitoIdentityServiceProvider.' + poolId + '.' + userid + '.idToken',
  );
};

export const getAccessTokenSync = () => {
  const poolId = cognitoConfig().aws_user_pools_web_client_id;
  const userid = localStorage.getItem(
    'CognitoIdentityServiceProvider.' + poolId + '.LastAuthUser',
  );
  return localStorage.getItem(
    'CognitoIdentityServiceProvider.' + poolId + '.' + userid + '.accessToken',
  );
};

export const getHeadersWithAuthorization = () => {
  return {
    Authorization: `Bearer ${getAccessTokenSync()}`,
  };
};

export function getUserData(): IUserData {
  const idTokenData: any = jwt_decode(getIdTokenSync());

  return {
    id: idTokenData.sub,
    username: idTokenData.sub,
    attributes: {
      sub: idTokenData.sub,
      email: idTokenData.email,
      email_verified: idTokenData.email,
    },
  };
}

export interface IUserData {
  id: string;
  username: string;
  attributes: {
    sub: string;
    email: string;
    email_verified: boolean;
  };
}
