import { gql } from '@apollo/client';

export const GET_PORTAL = gql`
  query QueryPortal($portalId: String!) {
    Elements(where: { Id: { _eq: $portalId } }) {
      Id
      Name
      SpaceId
      OwnerId
      CustomData
    }
  }
`;

export const ADD_NOTE = gql`
  mutation addNote($object: Elements_insert_input!) {
    insert_Elements(objects: [$object]) {
      returning {
        Color1_B
        Color1_G
        Color1_R
        Color2_B
        Color2_G
        Color2_R
        CustomData
        ElementType
        Id
        Name
        OwnerId
        ParentId
        SpaceId
      }
    }
  }
`;
