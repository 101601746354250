import { Input, InputProps } from 'antd';
import React, { useEffect, useState } from 'react';
const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

const PASSWORD_STRENGTH_CODE = [
  <span style={{ color: '#ff4d4f' }}>at least 8 characters</span>,
  <span>Unsafe</span>,
  <span>Good</span>,
  <span>Strong</span>,
];
const settings = {
  colorScheme: {
    levels: ['#FF0000', '#FFDD00', '#37DC69', '#606EE0'],
    noLevel: 'lightgrey',
  },
  height: 6,
  alwaysVisible: false,
};

interface LevelChangeProps {
  onLevelChange?: (newLevel: any) => void;
}

export interface PasswordInputProps extends LevelChangeProps {
  settings?: PasswordInputSettings;
}

const checkPasswordStrength = (p) => {
  if (p.length < 8) return 0;
  if (p.length > 8 && /[A-Z]/.test(p) && /\d/.test(p) && specialChars.test(p))
    return 3;
  if (p.length > 8 && /[A-Z]/.test(p) && /\d/.test(p)) return 2;
  return 1;
};

export const PasswordInput = React.forwardRef(
  (
    { onLevelChange, ...props }: PasswordInputProps & Partial<InputProps>,
    ref: any,
  ) => {
    const [input, setInput] = useState('');

    return (
      <>
        <Input.Password
          {...props}
          ref={ref}
          value={input}
          onChange={(e) => {
            setInput(e.target.value);
            props?.onChange?.(e);
          }}
        />
        <PasswordStrengthIndicator
          input={input}
          onLevelChange={onLevelChange}
          settings={settings}
        />
      </>
    );
  },
);

interface PasswordStrengthIndicatorProps extends LevelChangeProps {
  input: string;
  settings: PasswordInputSettings;
}

export const PasswordStrengthIndicator = ({
  input,
  settings,
  onLevelChange,
}: PasswordStrengthIndicatorProps) => {
  // Calculate level
  const level = React.useMemo(() => {
    return input.length == 0 ? -1 : checkPasswordStrength(input);
  }, [input]);

  // Calculate indicators
  const indicators: React.ReactElement[] = React.useMemo(() => {
    const ind = [];
    for (let i = 0; i < 4; i++) {
      const color =
        i <= level
          ? settings.colorScheme.levels[level]
          : settings.colorScheme.noLevel;
      ind.push(
        <div
          key={`indicator-${i}`}
          style={getIndicatorStyle(color, settings.height)}
        />,
      );
    }

    return ind;
  }, [level, settings]);

  useEffect(() => {
    onLevelChange?.(level);
  }, [level]);

  if (!settings.alwaysVisible && level < 0) {
    return null;
  }

  return (
    <div>
      <div style={getWrapperStyle(settings.height)}>{indicators}</div>
      <div style={{ textAlign: 'center', fontSize: 14, marginTop: 3 }}>
        {PASSWORD_STRENGTH_CODE[level]}
      </div>
    </div>
  );
};

function getWrapperStyle(height: number) {
  return {
    lineHeight: height + 'px',
  };
}

function getIndicatorStyle(color: string, height: number) {
  return {
    display: 'inline-block',
    width: '22%',
    margin: 4,
    backgroundColor: color,
    height: height + 'px',
    borderRadius: '2px',
  };
}

export interface PasswordInputSettings {
  colorScheme: ColorScheme;
  height: number;
  alwaysVisible: boolean;
}

export interface ColorScheme {
  levels: string[];
  noLevel: string;
}
