import { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import { GET_USER_BY_EMAIL } from '../../graphql/get-user-by-email.query';
import { UPDATE_USER } from '../../graphql/update-user.mutation';
import { ChangePassword } from './components/ChangePassword';
import { Privacy } from './components/Privacy';
import { NotificationTypes } from '../../shared/enums/notification-types.enum';
import { openNotificationWithIcon } from '../../shared/helpers/notify';
import { SCHEDULE_PROFILE_FOR_DELETE } from '../../graphql/schedule-profile-for-delete.mutation';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { saveUser, selectUser } from './userSlice';

export function Profile() {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const dispatch = useAppDispatch();

  const userState = useAppSelector(selectUser);
  const userRequest = useQuery(GET_USER_BY_EMAIL, {
    variables: { email: userState?.Email || '' },
  });

  const [updateUserFunc] = useMutation(UPDATE_USER);
  const [deleteProfileFunc] = useMutation(SCHEDULE_PROFILE_FOR_DELETE);

  useEffect(() => {
    if (!userRequest?.data?.Users) return;
    const [userData] = userRequest.data.Users;
    form.setFieldsValue(userData);
  }, [form, userRequest]);

  const handleSaveProfile = async (values) => {
    try {
      await updateUserFunc({
        variables: { sub: userState.Sub, ...values },
      });
      await openNotificationWithIcon({
        type: NotificationTypes.Success,
        message: 'Successfully updated.',
      });
      dispatch(saveUser({ ...userState, ...values }));
    } catch (e) {
      console.log(e.message);
      await openNotificationWithIcon({
        type: NotificationTypes.Error,
        message: 'Error trying update.',
      });
    }
  };

  const handleDeleteAccount = async () => {
    try {
      setConfirmLoading(true);

      await deleteProfileFunc({
        variables: { userId: userState.Sub, timestamp: new Date() },
      });
      setVisible(false);
      setConfirmLoading(false);

      openNotificationWithIcon({
        type: NotificationTypes.Success,
        message: 'You account has been successfully scheduled for deletion',
        description:
          'Your account will be processed and deleted in a week. If you have any questions please contact support.',
      });
    } catch (e) {
      console.log(e);
      openNotificationWithIcon({ type: NotificationTypes.Error, message: e });
    }
  };

  return (
    <div>
      <div className='whitebox'>
        <div>
          <h2>Personal</h2>
          <p>This Name will be also shown in the VISPA app</p>
        </div>

        <div>
          <Form
            onFinish={handleSaveProfile}
            name='basic'
            form={form}
            labelCol={{ span: 13 }}
            wrapperCol={{ span: 13 }}
            layout='vertical'
            autoComplete='off'
            initialValues={userState}
          >
            <Row justify='start'>
              <Col span={4}>
                <Form.Item
                  label='Firstname'
                  name='GivenName'
                  rules={[
                    { required: true, message: 'Please enter your first name' },
                  ]}
                >
                  <Input style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label='Lastname'
                  name='FamilyName'
                  rules={[
                    { required: true, message: 'Please enter your last name' },
                  ]}
                >
                  <Input style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={4}></Col>

              <Col span={4}>
                <Form.Item label='Email' name='Email'>
                  <Input disabled style={{ width: '100%' }} />
                </Form.Item>
              </Col>

              <Col span={3}>
                <Form.Item>
                  <Button type='primary' htmlType='submit'>
                    Save
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>

      <ChangePassword />

      <Privacy />

      <div className='whitebox'>
        <h2>Account Data</h2>
        <p>
          Here you can download all data we gathered from you, or delete your
          account.
        </p>
        <Button type='primary' onClick={() => setVisible(true)}>
          Delete Account
        </Button>

        <Modal
          className='whitebox-centered'
          visible={visible}
          confirmLoading={confirmLoading}
          onCancel={() => setVisible(false)}
        >
          <div className='whitebox-popup'>
            <div className='whitebox_Title'>
              <h2>Delete your Account?</h2>
            </div>
            <div className='whitebox-content'>
              <p className='centered-text'>
                You are about to delete your account. All the spaces you have
                created will be deleted. Make sure you transfer ownership to
                other users, as they will not be able to access your spaces
                after deletion as well. <br />
                <br />
                We will send you an email in which you have to confirm the
                account deletion.
              </p>
            </div>
            <Button
              onClick={handleDeleteAccount}
              type='primary'
              htmlType='submit'
              className='ant-btn-streched'
            >
              Delete
            </Button>
            <Button
              onClick={() => setVisible(false)}
              type='link'
              htmlType='submit'
              className='ant-btn-streched'
            >
              Cancel
            </Button>
          </div>
        </Modal>
      </div>
    </div>
  );
}
