// @ts-nocheck
import { useEffect } from 'react';
import { useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useResizeDetector } from 'react-resize-detector';
import { TwitterPicker } from 'react-color';
import { Stage, Layer, Rect, Text } from 'react-konva';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  agreeToTerms,
  selectPortal,
  setColor,
  startAnimationFrame,
  setB64Image,
} from '../../portalSlice';
import { ADD_NOTE } from '../../../../graphql/get-portal';
import { Button, Checkbox, Tooltip } from 'antd';
import { ButtonsType } from '../../../../shared/enums/buttons-type.enum';

import { openNotificationWithIcon } from '../../../../shared/helpers/notify';
import { NotificationTypes } from '../../../../shared/enums/notification-types.enum';

import './TextEditor.css';
import { IconComponent } from '../../../../components/Icon/Icon';
import { hexToRgbA } from '../../../../shared/helpers/hexToRGBA';
import TextareaAutosize from 'react-textarea-autosize';
import { useWindowSize } from '../../../../hooks/useWindowsSize';
const keyMap = {};

export const TextEditor = () => {
  const portal = useAppSelector(selectPortal);
  const dispatch = useAppDispatch();
  const [addNote] = useMutation(ADD_NOTE);
  const [text, setText] = useState('');

  const menuRef = useRef(null);
  const stageRef = useRef(null);

  const { width, height, ref } = useResizeDetector(); // this resize only works when reloaded
  const size = useWindowSize();

  useEffect(() => {
    const textEditor = document.getElementById('text-editor');
    if (window.innerWidth <= 350) {
      textEditor.style.fontSize = '33px';
    } else if (window.innerWidth > 350 && window.innerWidth <= 450) {
      textEditor.style.fontSize = '45px';
    } else {
      textEditor.style.fontSize = '70px';
    }
  }, []);

  useEffect(() => {
    const textEditor = document.getElementById('text-editor');
    const numOfBreaks = text.split('\n').length || 1;
    const validateForBreaks = /\r|\n/;

    if (
      validateForBreaks.exec(text.slice(-1)) &&
      validateForBreaks.exec(text.slice(-2)[0])
    ) {
      setText(text.slice(0, -1));
    }

    let fontSize: number;
    if (size?.width <= 350) {
      fontSize = 33;
      if (text.length >= 40) fontSize = 28;
      if (text.length >= 70) fontSize = 19;
      if (text.length >= 130) fontSize = 16;
    } else if (size?.width >= 350 && size?.width <= 450) {
      fontSize = 45;
      if (text.length >= 40) fontSize = 35;
      if (text.length >= 70) fontSize = 28;
      if (text.length >= 130) fontSize = 21;
    } else {
      if (size?.width) {
        fontSize = 70;
        if (text.length >= 40) fontSize = 54;
        if (text.length >= 70) fontSize = 42;
        if (text.length >= 130) fontSize = 32;
        if (text.length >= 249) fontSize = 28;
      }
    }

    if (numOfBreaks > 1) {
      fontSize /= numOfBreaks * 0.4;
    }

    textEditor.style.fontSize = fontSize + 'px';
  }, [text]);

  const twitterPicker = (
    <TwitterPicker
      style={{ margin: 'auto' }}
      triangle='hide'
      color={portal.color}
      colors={[
        '#FFFCA4',
        '#C4FFC8',
        '#FFC4BC',
        '#C1D6FC',
        '#FFD19E',
        '#C4FFE3',
        '#E5C2FF',
        '#FFFFFF',
      ]}
      onChangeComplete={(c) => {
        document.getElementById('text-editor').style.backgroundColor = c.hex;
        dispatch(setColor(c.hex));
      }}
    />
  );

  const sendNote = () => {
    if (!text || text.trim().length === 0) {
      openNotificationWithIcon({
        message: 'Please enter a text to send your note',
        type: NotificationTypes.Error,
      });
      return;
    }

    if (!portal.agreeToTerms) {
      openNotificationWithIcon({
        message:
          'Please accept our Privacy Policy and Terms of Use located under the "Send" button.',
        type: NotificationTypes.Error,
      });
      return;
    }
    const rgb = hexToRgbA(portal.color);
    const [r, g, b] = rgb && Array.isArray(rgb) ? rgb.map((c) => c / 255) : [];

    const note: any = {
      SpaceId: portal.spaceId,
      ParentId: portal.id,
      ElementType: 'Note',
      Name: `Note_${text.split(' ')[0]}`,
      Color1_R: 0.0,
      Color1_G: 0.0,
      Color1_B: 0.0,
      Color2_R: 0.0,
      Color2_G: 0.0,
      Color2_B: 0.0,
      Scale_X: 0.5,
      Scale_Y: 0.5,
      Scale_Z: 0.01,
    };

    if (r) {
      note.CustomData = JSON.stringify({
        Text: text,
        Colors: [{ r, g, b, a: 1 }],
      });
    } else {
      note.CustomData = JSON.stringify({
        Text: text,
        Colors: [{ r: 1, g: 1, b: 1, a: 1 }],
      });
    }

    const uri = stageRef.current.toDataURL();
    dispatch(setB64Image(uri));

    try {
      addNote({ variables: { object: note } })
        .then(() => {
          dispatch(startAnimationFrame(true)); // Start the animation
          openNotificationWithIcon({
            message: 'Note successfully sent',
            type: NotificationTypes.Success,
          });
          setText('');
          document.getElementById('text-editor').focus();
        })
        .catch((err) => {
          if (err.message.includes('permission')) {
            openNotificationWithIcon({
              message:
                'The space in which the portal is located is unfortunately not shared with you. Please log out to use the public portal.',
              type: NotificationTypes.Error,
            });
          }
        });
    } catch (err) {
      openNotificationWithIcon({
        message: 'Something bad happened. Please try again',
        type: NotificationTypes.Error,
      });
    }
  };

  const handleKeyDown = (e) => {
    keyMap[e.keyCode] = true;
    if (keyMap[13] && keyMap[16]) sendNote();
  };

  const handleKeyUp = (e) => {
    keyMap[e.keyCode] = false;
  };

  return (
    <div id='mystage'>
      <Stage
        id='mystage'
        width={width ? width : 1}
        height={height ? height : 1}
        ref={stageRef}
        style={{ opacity: 0, display: 'none' }}
      >
        <Layer>
          <Rect width={width} height={height} fill={portal.color} opacity={1} />
          <Text
            text={text}
            align='center'
            verticalAlign='middle' // or 'top'
            width={width}
            height={height}
            fontSize={35}
            fontFamily='Gilroy'
          />
        </Layer>
      </Stage>
      <div className='portal-wrapper' style={{ height: width }}>
        <div className='portal-inner-wrapper' ref={ref}>
          <div
            className='portal-content-wrapper'
            style={{ height, backgroundColor: portal.color, display: 'flex' }}
          >
            <TextareaAutosize
              id='text-editor'
              style={{
                width: '100%',
                backgroundColor: portal.color,
                ':focus-visible': {
                  outline: 'none',
                },
              }}
              autoFocus
              value={text}
              type='text'
              placeholder='Type something'
              onChange={(e) => setText(e.target.value)}
              maxLength={250}
              onKeyUp={handleKeyUp}
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>

        <div className='portal-editmenu-wrapper' ref={menuRef}>
          <div className='portal-editmenu'>
            <Tooltip
              style={{ backgroundColor: 'white', margin: '50%' }}
              title={twitterPicker}
            >
              <div className='color-btn'>
                <span
                  style={{
                    display: 'flex',
                    height: 25,
                    width: 25,
                    border: '1px solid #D6D6D6',
                    borderRadius: '50%',
                    margin: 'auto',
                    backgroundColor: portal.color,
                  }}
                ></span>
              </div>
            </Tooltip>
            <Button
              id='portal-send-btn'
              type={ButtonsType.Link}
              onClick={sendNote}
            >
              Send
              <IconComponent props={{ iconName: 'icon-Send' }} />
            </Button>
          </div>
        </div>
      </div>
      <Checkbox
        className='Legal-checkbox'
        checked={portal.agreeToTerms}
        style={{ color: !portal.agreeToTerms && '#ff4d4f' }}
        onClick={() => dispatch(agreeToTerms())}
      >
        I have read and accept the
        <a href='https://www.vispa.io/privacy' target={'blank'}>
          {' '}
          Privacy Policy
        </a>{' '}
        and{' '}
        <a
          href='https://app.hubspot.com/documents/20393810/view/266024796?accessId=3a9a80'
          target={'blank'}
        >
          Terms of use
        </a>
        .
      </Checkbox>
    </div>
  );
};
