import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { Plans } from './components/Plan/Plan';
import {
  GET_COMPANY,
  GET_COMPANY_BY_MEMBER,
} from '../../graphql/get-company.query';
import { OrganizationView } from './components/Organization/OrganizationView';
import { Loading } from '../../components/Loading/Loading';
import { CurrentPlan } from './components/CurrentPlan/CurrentPlan';
import { SubscriptionsRequest } from './enums/subsriptions-request.enum';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  saveUserPlan,
  saveFreeUserPlanSelectd,
  triesToMakePayment,
} from './userPlanSlice';
import { usePaymentResourcesMutation } from '../Api/baseApi';
import { getUserData } from '../Api/helpers/get-user-data';
import { Members } from './components/Members/Members';
import { selectUser, UserTypes } from '../Profile/userSlice';
import { useQuery } from '@apollo/client';
import { useLocationQuery } from '../../hooks/useLocationQuery';

export function Payment() {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  let query = useLocationQuery();

  const [visiblePlans, setVisiblePlans] = useState<boolean>(false);
  const [visibleResources, setVisibleResources] = useState<boolean>(false);
  const [visiblePlanModal, setVisiblePlanModal] = useState<boolean>(false);
  const [iframeUrl, setIframeUrl] = useState(null);
  const [organization, setOrganization] = useState<any | null>(null);

  const [paymentResources] = usePaymentResourcesMutation();
  const company = useQuery(GET_COMPANY);
  const companyByMember = useQuery(GET_COMPANY_BY_MEMBER);

  useEffect(() => {
    getManageForm().then((r) => r);
  }, []);

  const element = document.getElementById(query.get('component'));
  if (element) element.scrollIntoView({ behavior: 'smooth' });

  const getManageForm = async () => {
    try {
      const userData = await getUserData();
      console.log('userdata', userData);
      paymentResources({ requestType: SubscriptionsRequest.ManageSubscription })
        .unwrap()
        .then(async (fulfilled) => {
          setIframeUrl(fulfilled.hosted_page.hosted_page.url);
          const { data: orgData } = await company.refetch({
            adminId: userData.attributes.sub,
          });
          setOrganization(orgData?.OrganizationMembers[0].Organization);

          paymentResources({
            requestType: SubscriptionsRequest.GetSubscription,
          })
            .unwrap()
            .then((fulfilled) =>
              dispatch(saveUserPlan(fulfilled.subscriptionData)),
            )
            .catch((rejected) => {
              console.log('Get subscription error:');
              console.error(rejected);
            });
        })
        .catch(async (rejected) => {
          console.log(
            '[SubscriptionsRequest.ManageSubscription] failed. Trying to get subscription for non owner.',
          );
          const companyMemberResponse = await companyByMember.refetch({
            userId: userData.username,
          });
          const organization =
            companyMemberResponse?.data?.OrganizationMembers[0]?.Organization;
          if (!organization) return;
          setOrganization(organization);
          dispatch(
            saveUserPlan({
              ...organization,
              subscription_items: [
                { item_price_id: organization?.SubscriptionPlan },
              ],
            }),
          );
        });
    } catch (e) {
      console.log('GetManageForm error ===> ', e);
    }
  };

  const handlePlanClickForFreeUser = async (plan_id) => {
    dispatch(saveFreeUserPlanSelectd(plan_id));
    dispatch(triesToMakePayment(true));
    setVisiblePlans(false);
    setVisiblePlanModal(true);
  };

  return (
    <div id='payment' className='container'>
      <div className='whitebox'>
        <h2>
          {' '}
          {user?.Type === UserTypes.Owner ||
          user?.Type === UserTypes.Admin ||
          user?.Type === UserTypes.Member
            ? 'My subscription'
            : 'My plan'}
        </h2>
        <p>Details about your plan and subscription</p>

        {company.loading && <Loading />}

        <div>
          <div>
            {user?.Type === UserTypes.Owner && (
              <OrganizationView organization={organization} />
            )}
            <CurrentPlan
              visiblePlanModal={visiblePlanModal}
              setVisibleResources={setVisibleResources}
              setVisiblePlanModal={setVisiblePlanModal}
              setVisiblePlans={setVisiblePlans}
            />
          </div>
        </div>

        <Modal
          footer={[]}
          centered
          visible={visiblePlans}
          onOk={() => setVisiblePlans(false)}
          onCancel={() => setVisiblePlans(false)}
          className='pricing-cards'
        >
          <Plans handlePlanClick={handlePlanClickForFreeUser} />
        </Modal>

        <Modal
          footer={[]}
          centered
          visible={visibleResources}
          onOk={() => setVisibleResources(false)}
          className='pricing-cards'
          onCancel={() => setVisibleResources(false)}
        >
          <iframe
            src={iframeUrl}
            frameBorder='0'
            scrolling='no'
            className='order-card'
            title='Iframe Example'
          >
            Browser not compatible.
          </iframe>
        </Modal>
      </div>

      <Members />
    </div>
  );
}
