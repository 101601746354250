import { Col, Form, Input, Row } from 'antd';

export function OrganizationView({ organization }) {
  if (!organization) return null;
  organization = { ...organization, ...organization.Admin };
  return (
    <div>
      {organization && (
        <Form
          name='basic'
          labelCol={{ span: 13 }}
          wrapperCol={{ span: 13 }}
          initialValues={organization}
          layout='vertical'
          autoComplete='off'
          id='organisation-inputs'
        >
          <Row justify='start' id='organisation-row-1'>
            <Col span={4}>
              <Form.Item label='Company' name='Name'>
                <Input style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label='Email' name='Email'>
                <Input disabled style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>

          <Row justify='start' id='organisation-row-2'>
            <Col span={2}>
              <Form.Item label='Street' name='Street'>
                <Input style={{ width: '100%' }} />
              </Form.Item>
            </Col>

            <Col span={2}>
              <Form.Item label='City' name='City'>
                <Input style={{ width: '100%' }} />
              </Form.Item>
            </Col>

            <Col span={2}>
              <Form.Item label='Postal Code' name='PostalCode'>
                <Input style={{ width: '100%' }} />
              </Form.Item>
            </Col>

            <Col span={2}>
              <Form.Item label='Country' name='Country'>
                <Input style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </div>
  );
}
