import { Button, Col, Form, Input } from 'antd';
import { ScreenTypes } from '../types/screen-types';
import React, { useState } from 'react';
import { getErrorWithText } from '../../../shared/helpers/getErrorWithText';
import { emailRegex } from '../../../shared/regex/email-regex';
import { IconComponent } from '../../../components/Icon/Icon';
import GoogleSvg from '../../../assets/icons/google.svg';
import { APPLE_AUTH_LINK, GOOGLE_AUTH_LINK } from '../../../config/env';
import { Terms } from './Terms';

const getStylesForContinueWithButton = () => {
  return { position: 'absolute', left: 10, top: 12 };
};

export function SignIn(props) {
  const [visibleTerms, setVisibleTerms] = useState(false);
  const [termsUrl, setTermsUrl] = useState('');

  return (
    <div className='whitebox-popup' style={{ margin: '2rem 0 0 0' }}>
      <div className='whitebox_Title'>
        <h2>Login</h2>
      </div>
      <Form
        name='basic'
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={props.handleSignIn}
        autoComplete='off'
        validateTrigger='submit'
      >
        <Col span={4}>
          <Form.Item>
            <Button
              className='ant-btn-streched sso-buttons'
              onClick={() => {
                setVisibleTerms(true);
                setTermsUrl(GOOGLE_AUTH_LINK);
              }}
            >
              <img
                src={GoogleSvg}
                style={{
                  fontSize: 20,
                  left: 10,
                  top: 12,
                  position: 'absolute',
                  width: 20,
                }}
              />
              Sign in with Google
            </Button>
            <Button
              className='ant-btn-streched sso-buttons'
              onClick={() => {
                setVisibleTerms(true);
                setTermsUrl(APPLE_AUTH_LINK);
              }}
            >
              <IconComponent
                props={{
                  iconName: 'icon-Apple',
                  style: getStylesForContinueWithButton(),
                }}
              />
              Sign in with Apple
            </Button>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label='Work Email'
            name='username'
            style={{ borderColor: !!props.wrongPasswordError && 'red' }}
            className='SignIn_WorkEmail'
            rules={[
              {
                required: true,
                message: getErrorWithText('Enter your email address'),
                whitespace: true,
              },
              {
                type: 'email',
                message: getErrorWithText('Enter a valid email address'),
              },
              {
                pattern: new RegExp(emailRegex),
                message: getErrorWithText(
                  'Sorry, only letters (A-z), number (0-9), and periods (.) are allowed.',
                ),
              },
            ]}
          >
            <Input
              style={{
                width: '100%',
                borderColor: !!props.wrongPasswordError && 'red',
              }}
            />
          </Form.Item>
        </Col>

        <Form.Item
          label='Password'
          name='password'
          className='SignIn_Password'
          rules={[
            {
              required: true,
              message: getErrorWithText('Enter your password'),
              whitespace: true,
            },
          ]}
        >
          <Input.Password
            style={{ borderColor: !!props.wrongPasswordError && 'red' }}
          />
        </Form.Item>

        {props.wrongPasswordError && (
          <p>{getErrorWithText(props.wrongPasswordError)}</p>
        )}

        <div id='forgot-password'>
          <span
            onClick={() => props.setScreen(ScreenTypes.ResetPassword)}
            className='text-btn cursor-pointer'
          >
            Forgot your password?
          </span>
        </div>

        <Form.Item wrapperCol={{ span: 16 }}>
          <Button
            type='primary'
            htmlType='submit'
            className='ant-btn-streched'
            loading={props.loginLoading}
          >
            Login
          </Button>
        </Form.Item>

        <div className='bottom-text'>
          Don't have an account?
          <span
            onClick={() => props.setScreen(ScreenTypes.SignUp)}
            className='text-btn cursor-pointer'
          >
            {' '}
            Sign up
          </span>
        </div>
      </Form>

      <Terms
        source={'signIn'}
        setVisibleTermsModal={setVisibleTerms}
        visible={visibleTerms}
        url={termsUrl}
      />
    </div>
  );
}
