import { gql } from '@apollo/client';

export const UPDATE_USER_PRIVACY = gql`
  mutation UpdateUser(
    $sub: String!
    $TrackingEnabled: Boolean
    $NewsEnabled: Boolean
  ) {
    update_Users(
      where: { Sub: { _eq: $sub } }
      _set: { TrackingEnabled: $TrackingEnabled, NewsEnabled: $NewsEnabled }
    ) {
      affected_rows
    }
  }
`;
