import { Checkbox } from 'antd';
import { useMutation } from '@apollo/client';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectUser } from './../userSlice';
import { UPDATE_USER_PRIVACY } from '../../../graphql/update-user-privacy';
import { saveUser } from '../userSlice';

const checkboxStyle = {
  fontSize: 18,
  marginTop: '0.8rem',
};

export function Privacy() {
  const [updateUserPrivacy] = useMutation(UPDATE_USER_PRIVACY);
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const handleFieldChange = (e) => {
    dispatch(saveUser({ ...user, [e.target.name]: e.target.checked }));
    updateUserPrivacy({
      variables: { ...user, sub: user.Sub, [e.target.name]: e.target.checked },
    }).catch((e) => console.error(e));
  };

  return (
    <div className='whitebox'>
      <h2>Privacy</h2>
      <p>Set up your privacy Settings.</p>

      <Checkbox
        name='NewsEnabled'
        checked={user?.NewsEnabled}
        style={checkboxStyle}
        onChange={(e) => handleFieldChange(e)}
      >
        I agree to receive news and updates from VISPA.
      </Checkbox>
      <br />
      <Checkbox
        name='TrackingEnabled'
        checked={user?.TrackingEnabled}
        style={checkboxStyle}
        onChange={(e) => handleFieldChange(e)}
      >
        I agree to usage analysis to help improve VISPA. More information:{' '}
        <a href='https://www.vispa.io/privacy' target='_blank' rel='noreferrer'>
          Privacy Policy.
        </a>
      </Checkbox>
    </div>
  );
}
