import { plans } from './plans';
import { PlansType } from '../../interfaces/plans-type';
import { Button, Card } from 'antd';
import { IPlan } from '../../interfaces/plan.interface';
import { useState } from 'react';
import { IconComponent } from '../../../../components/Icon/Icon';
import { ButtonsType } from '../../../../shared/enums/buttons-type.enum';
import { PlanTypes } from '../../enums/PlanTypes.enum';

interface IPlansProps {
  handlePlanClick: (string?) => void;
}

const buttonStyle = {
  color: 'black',
  backgroundColor: 'white',
  borderStyle: 'none',
  borderRadius: 25,
};

const buttonStyleActive = {
  color: 'white',
  backgroundColor: '#626DE0',
  borderStyle: 'none',
  borderRadius: 25,
};

const enterpriseContactAddress =
  'https://meetings.hubspot.com/manuel176/discovery-call-enterprise?hsCtaTracking=296eacf1-2ed6-4311-b2dd-53b9477ba81d%7C241ea857-2071-45dc-9be6-19d27cd7622d';

export function Plans(props: IPlansProps) {
  const { handlePlanClick }: IPlansProps = props;
  const [planType, setPlanType] = useState<PlansType>(PlanTypes.Yearly);

  return (
    <div>
      <div className='plans-buttons-switch'>
        <Button
          className='Monthly-btn'
          style={
            planType !== PlanTypes.Monthly ? buttonStyle : buttonStyleActive
          }
          onClick={() => setPlanType(PlanTypes.Monthly)}
        >
          Monthly
        </Button>
        <Button
          className='Yearly-btn'
          style={
            planType === PlanTypes.Monthly ? buttonStyle : buttonStyleActive
          }
          onClick={() => setPlanType(PlanTypes.Yearly)}
        >
          Yearly
          <span
            className='Discount'
            style={{
              backgroundColor: '#7BD181',
              color: 'white',
              borderRadius: '23px',
              padding: '4px 14px',
            }}
          >
            {' '}
            -20%
          </span>
        </Button>
      </div>

      <div className='list'>
        {plans
          .filter((p: IPlan) => p.type === planType)
          .map((plan: IPlan) => {
            return plan.recommended ? (
              <RecommendedPlanComponent
                plan={plan}
                handlePlanClick={handlePlanClick}
              />
            ) : (
              <PlanComponent plan={plan} handlePlanClick={handlePlanClick} />
            );
          })}
      </div>
    </div>
  );
}

const PlanComponent = ({ plan, handlePlanClick }) => {
  return (
    <div key={plan.id} className='list-item'>
      <div className='list-item-recommended-empty'></div>
      <PlanCardComponent
        plan={plan}
        handlePlanClick={handlePlanClick}
        customClass=''
      />
    </div>
  );
};

const RecommendedPlanComponent = ({ plan, handlePlanClick }) => {
  return (
    <div key={plan.id} className='list-item'>
      <div className='list-item-recommended'>Recommended</div>
      <PlanCardComponent
        plan={plan}
        handlePlanClick={handlePlanClick}
        customClass='plan-card-recommended'
      />
    </div>
  );
};

const PlanCardComponent = ({ plan, handlePlanClick, customClass }) => {
  return (
    <Card className={`list-content plan-card ${customClass}`} hoverable>
      <h2 className='plan-title'>{plan.name}</h2>
      <p className='plan-sub-title'>{plan.nameDesc}</p>
      <h2 className='plan-price'>{plan.price}</h2>
      <p className='plan-small-text'>{plan.priceDesc}</p>
      {plan.name === 'Enterprise' ? (
        <Button className='ant-btn-streched' type={ButtonsType.Primary}>
          <a href={enterpriseContactAddress} target='_blank'>
            {plan.buttonText}
          </a>
        </Button>
      ) : (
        <Button
          onClick={() => handlePlanClick(plan.id)}
          className='ant-btn-streched'
          type={ButtonsType.Primary}
        >
          {plan.buttonText}
        </Button>
      )}
      <div className='plan-btn-subtext'>{plan.buttonSubText}</div>
      <h2 className='plan-features-title'>{plan.features.name}</h2>
      <div className='plan-features-list'>
        {plan.features.items.map((i) => (
          <div key={i} className='plan-features'>
            <IconComponent
              props={{
                iconName: 'icon-Done_Circle',
                style: { color: '#606ee0', fontSize: '24px' },
              }}
            />
            {i}
          </div>
        ))}
        {plan.features.items2.map((i) => (
          <div key={i} className='plan-features plan-features-font'>
            <IconComponent
              props={{
                iconName: 'icon-User_Group',
                style: { color: '#606ee0', fontSize: '24px' },
              }}
            />
            {i}
          </div>
        ))}
        {plan.features.items3.map((i) => (
          <div key={i} className='plan-features plan-features-font'>
            <IconComponent
              props={{
                iconName: 'icon-User_Add_right',
                style: { color: '#606ee0', fontSize: '24px' },
              }}
            />
            {i}
          </div>
        ))}
      </div>
    </Card>
  );
};
