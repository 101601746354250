import { Button, Form, Input, Modal, Select } from 'antd';
import { ButtonsType } from '../../../../shared/enums/buttons-type.enum';
import React, { useState } from 'react';
import { useAskForMoreMutation } from '../../../Api/org-members';
import { getUserData } from '../../../Api/helpers/get-user-data';

const askForMoreOptions = [];

for (let i = 1; i <= 50; i++) {
  askForMoreOptions.push({ value: i, label: i });
}

const DEFAULT_ASK_FOR_MORE_VALUE = 10;

export function AskForMoreModal(props) {
  const [sent, setSent] = useState<boolean>(false);
  const [askForMoreMutation] = useAskForMoreMutation();

  async function handleSendAskForMoreUso(values) {
    setSent(true);
    const uso = values?.uso?.value || DEFAULT_ASK_FOR_MORE_VALUE;
    const user = await getUserData();
    askForMoreMutation({ userId: user.username, uso })
      .unwrap()
      .then(async (fulfilled) => {
        console.log(fulfilled);
      });
  }

  if (sent) {
    return (
      <Modal
        footer={[]}
        visible={props.visibleAskForMoreModal}
        className='whitebox-centered'
        onCancel={() => {
          props.setVisibleAskForMoreModal(false);
        }}
      >
        <div className='whitebox-popup'>
          <div className='whitebox_Title'>
            <h2>Request sent</h2>
          </div>
          <p className='centered-text'>
            Thank you for your request, your admin will be informed via email
            about your request.
          </p>

          <Button
            className='ant-btn-streched'
            type={ButtonsType.Link}
            onClick={() => props.setVisibleAskForMoreModal(false)}
          >
            Close
          </Button>
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      footer={[]}
      visible={props.visibleAskForMoreModal}
      className='whitebox-centered'
      onCancel={() => {
        props.setVisibleAskForMoreModal(false);
      }}
    >
      <div className='whitebox-popup'>
        <div className='whitebox_Title'>
          <h2>Ask for more USOs</h2>
        </div>
        <p className='centered-text'>
          The administrator of your subscription will receive an email with the
          number of concurrent users you requested.
        </p>

        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          autoComplete='off'
          onFinish={handleSendAskForMoreUso}
        >
          <Form.Item label='Concurrent Users' name='uso'>
            <Select
              labelInValue
              defaultValue={{
                value: DEFAULT_ASK_FOR_MORE_VALUE,
                label: DEFAULT_ASK_FOR_MORE_VALUE,
              }}
              style={{ width: '100%' }}
              options={askForMoreOptions}
            />
          </Form.Item>

          <Button
            htmlType='submit'
            className='ant-btn-streched'
            type={ButtonsType.Primary}
          >
            Send
          </Button>
        </Form>

        <Button
          className='ant-btn-streched'
          type={ButtonsType.Link}
          onClick={() => props.setVisibleAskForMoreModal(false)}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
}
