import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getHeadersWithAuthorization } from './helpers/get-user-data';
import { apiGatewayUrlDevEnv } from '../../config/env';

const api = createApi({
  reducerPath: 'baseApi',
  baseQuery: fetchBaseQuery({ baseUrl: apiGatewayUrlDevEnv() }),
  endpoints: (builder) => ({
    paymentResources: builder.mutation<any, any>({
      query: (body) => ({
        url: 'payment-resources',
        method: 'POST',
        body,
        headers: getHeadersWithAuthorization(),
      }),
    }),
    checkout: builder.mutation<any, any>({
      query: (body) => ({
        url: 'checkout-page',
        method: 'POST',
        body,
      }),
      transformResponse: (response: any) => response.hostedPage.hosted_page,
    }),
  }),
});

export const { usePaymentResourcesMutation, useCheckoutMutation } = api;
