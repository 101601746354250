import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { apiGatewayUrlProdEnv } from '../../config/env';

const orgMembersApi = createApi({
  reducerPath: 'orgMembersApi',
  baseQuery: fetchBaseQuery({ baseUrl: apiGatewayUrlProdEnv() }),
  endpoints: (builder) => ({
    inviteMembers: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: 'orgmembers',
          method: 'POST',
          body,
        };
      },
      transformResponse: (response: any) => response.data,
    }),
    askForMore: builder.mutation({
      query: (body) => ({
        url: 'subscription-uso',
        method: 'POST',
        body,
      }),
    }),
    session: builder.mutation<any, any>({
      query: (body) => ({
        url: 'sso',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useInviteMembersMutation,
  useAskForMoreMutation,
  useSessionMutation,
} = orgMembersApi;
