import { gql } from '@apollo/client';

export const TRACK_EVENT = gql`
  mutation TrackEvent(
    $userId: String
    $category: String
    $name: String
    $action: String
    $value: Int
  ) {
    insert_AnalyticsEvents(
      objects: {
        UserId: $userId
        Category: $category
        Name: $name
        Action: $action
        Value: $value
      }
    ) {
      affected_rows
    }
  }
`;
