import { Button, Col, Form, Input, Row } from 'antd';
import { Auth } from 'aws-amplify';
import { openNotificationWithIcon } from '../../../shared/helpers/notify';
import { NotificationTypes } from '../../../shared/enums/notification-types.enum';
import { PasswordInput } from '../../../components/PasswordInput/PasswordInput';
import React, { useState } from 'react';

export function ChangePassword() {
  const [changePasswordForm] = Form.useForm();
  const [level, setLevel] = useState(0);

  const changePassword = ({ currentPassword, password }): void => {
    Auth.currentAuthenticatedUser()
      .then((user) => Auth.changePassword(user, currentPassword, password))
      .then((data) => {
        changePasswordForm.resetFields();
        openNotificationWithIcon({
          type: NotificationTypes.Success,
          message: 'Password changed successfully',
        });
      })
      .catch((err) => {
        openNotificationWithIcon({
          type: NotificationTypes.Error,
          message: err.message,
        });
        console.log(err);
      });
  };

  return (
    <div className='whitebox'>
      <h2>Change Password</h2>
      <p>
        Your password requires to include at least 8 characters, uppercase,
        lowercase, number and special chars.
      </p>

      <Form
        form={changePasswordForm}
        labelCol={{ span: 13 }}
        wrapperCol={{ span: 13 }}
        layout='vertical'
        onFinish={changePassword}
        autoComplete='off'
      >
        <Row>
          <Col span={4}>
            <Form.Item label='Current Password' name='currentPassword'>
              <Input.Password />
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item
              label='Password'
              name='password'
              rules={[
                {
                  validator: async () => {
                    return level >= 0
                      ? Promise.resolve()
                      : Promise.reject('At least 8 characters');
                  },
                  message: 'Password is too weak',
                },
              ]}
            >
              <PasswordInput onLevelChange={setLevel} />
            </Form.Item>
          </Col>

          <Col span={3}>
            <Form.Item>
              <Button type='primary' htmlType='submit'>
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
