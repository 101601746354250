import { VFC, useState, useEffect } from 'react';
import { getIdTokenSync } from '../Api/helpers/get-user-data';
import { LoadingComponent } from './components/LoadingComponent';
import { useAppDispatch } from '../../app/hooks';
import { hideSidebar } from '../App/appSlice';
import { getWebGlBuild } from '../../config/env';
import logo from '../../assets/images/Vispa_Logo_137x156.svg';
import sadLogo from '../../assets/images/Sad_MobileDeivce.png';

declare global {
  interface Window {
    unityInstance?: any;
  }
}

const BUILD = getWebGlBuild();

export const Webgl: VFC = (): JSX.Element => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [progression, setProgression] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  const dispatch = useAppDispatch();
  dispatch(hideSidebar(false));

  useEffect(() => {
    const isMobileOrTablet = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    // for testing purposes (from browser)
    // const { innerWidth, innerHeight } = window;
    // const isMobileOrTablet = innerWidth <= 767 || innerHeight <= 767;

    setIsMobile((isMobileOrTablet));
  }, [])

  useEffect(() => {
    const idToken = getIdTokenSync() || 'anonymous'; // for temp users use anonymous idtoken.
    if (isLoaded)
      window.unityInstance?.SendMessage(
        'WebGLController',
        'SetIdToken',
        idToken,
      );
  }, [isLoaded]);

  useEffect(() => {
    if (isMobile) return;
    const angle = 0;
    const buildUrl = BUILD + 'Build';
    const loaderUrl = buildUrl + '/VISPA.loader.js';
    const config = {
      dataUrl: buildUrl + '/VISPA.data',
      frameworkUrl: buildUrl + '/VISPA.framework.js',
      codeUrl: buildUrl + '/VISPA.wasm',
      streamingAssetsUrl: BUILD + 'StreamingAssets',
      companyName: 'SpaceOne',
      productName: 'VISPA_',
      productVersion: '1.1.1 (4910)',
    };

    const container = document.querySelector('#unity-container');
    const canvas = document.querySelector('#unity-canvas');

    const script = document.createElement('script');
    script.src = loaderUrl;
    script.onload = () => {
      // @ts-ignore
      createUnityInstance(canvas, config, (p) => {
        setProgression(p);
      })
        .then((unityInstance2) => {
          setIsLoaded(true);
          window.unityInstance = unityInstance2;
        })
        .catch((message) => {
          alert(message);
        });
    };
    document.body.appendChild(script);
  }, []);

  return isMobile
    ?
    <div className='webgl-container' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      <img
        style={{
          margin: '0 5rem',
          width: 137,
          height: 156,
        }}
        src={logo}
        alt='logo'
      />
      <img
        style={{
          marginTop: 60,
          marginBottom: 24,
          height: 156,
      }}
        src={sadLogo}
        alt='unhappy'
      />
      <p style={{ textAlign: 'center', fontSize: 32, lineHeight: 1.4, maxWidth: 500 }}>Sorry, VISPA is not supported on mobile devices!</p>
    </div>
    :
 <div className='webgl-container'>
      {!isLoaded && (
        <LoadingComponent props={{ loadingProgression: progression }} />
      )}

      <div
        style={{ display: isLoaded ? 'flex' : 'none' }}
        id='unity-container'
        className='unity-desktop'
      >
        <canvas
          id='unity-canvas'
          style={{ width: '100vw', height: '100vh' }}
        ></canvas>
      </div>
    </div>
};
