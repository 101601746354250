import { gql } from '@apollo/client';

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $sub: String!
    $FamilyName: String
    $GivenName: String
    $OrganizationName: String
  ) {
    update_Users(
      where: { Sub: { _eq: $sub } }
      _set: {
        FamilyName: $FamilyName
        GivenName: $GivenName
        OrganizationName: $OrganizationName
      }
    ) {
      affected_rows
    }
  }
`;
