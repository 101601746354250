import { gql } from '@apollo/client';

export const GET_ORG_MEMBERS = gql`
  query GetOrganizationMembersByOrganizationId($orgId: String!) {
    OrganizationMembers(where: { OrganizationId: { _eq: $orgId } }) {
      Type
      User {
        Email
        GivenName
        FamilyName
        Id
      }
    }
  }
`;
