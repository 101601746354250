export const mapPathToTopic = (locationPath: string): number => {
  let topicId: number = -1;

  switch (locationPath) {
    case '/':
      topicId = 0;
      break;
    case '/profile':
      topicId = 1;
      break;
    case '/payment':
      topicId = 2;
      break;
  }

  return topicId;
};
