import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import { getUserData } from '../../../Api/helpers/get-user-data';
import { useMutation } from '@apollo/client';
import { LEAVE_CURRENT_SUBSCRIPTION } from '../../../../graphql/get-company.query';
import { ButtonsType } from '../../../../shared/enums/buttons-type.enum';
import { useAppSelector } from '../../../../app/hooks';
import { selectUserPlan } from '../../userPlanSlice';

export function LeaveSubscriptionModal(props) {
  const userPlan = useAppSelector(selectUserPlan);
  const [leaveCurrentSubscription] = useMutation(LEAVE_CURRENT_SUBSCRIPTION);
  const [leaveButtonLoading, setLeaveButtonLoading] = useState(false);

  async function leaveCurrentSubscriptionAsMember() {
    setLeaveButtonLoading(true);
    const userData = await getUserData();
    await leaveCurrentSubscription({
      variables: { userId: userData.username },
    });
    window.location.reload();
  }
  return (
    <Modal
      footer={[]}
      visible={props.visibleLeaveSubscriptionModal}
      className='whitebox-centered'
      onCancel={() => {
        props.setVisibleLeaveSubscriptionModal(false);
      }}
    >
      <div className='whitebox-popup'>
        <div className='whitebox_Title'>
          <h2>Leave Current subscription?</h2>
        </div>
        <p className='centered-text'>
          Do you really want to leave your active subscription of{' '}
          <b>{userPlan?.value?.Name}</b>?
        </p>

        <p className='centered-text'>
          After you left the subscription you will be a free user and you will
          only have access to the latest personal and shared space. All other
          spaces will still be there but locked.
        </p>

        <p className='centered-text'>
          You can choose and upgrade to a new plan at any time after this step.
        </p>
        <Button
          className='ant-btn-streched'
          type={ButtonsType.Primary}
          onClick={() => leaveCurrentSubscriptionAsMember()}
          loading={leaveButtonLoading}
        >
          Leave
        </Button>
        <Button
          className='ant-btn-streched'
          type={ButtonsType.Link}
          onClick={() => props.setVisibleLeaveSubscriptionModal(false)}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
}
