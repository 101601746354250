import { IPlan } from '../../interfaces/plan.interface';
import { PlanId } from './plan-id.enum';

export const plans: IPlan[] = [
  {
    id: PlanId.StartedYearly,
    type: 'yearly',
    name: 'Starter',
    nameDesc: 'Perfect for running virtual workshops in small teams.',
    price: '12€',
    rawPrice: 12,
    priceDesc: 'Per Month & User',
    buttonText: 'Buy now',
    buttonSubText: 'Cancel yearly',
    features: {
      name: 'Free features +',
      items: [
        'Upload limit 2GB',
        'Unlimited personal & shared spaces',
        'Basic onboarding',
        'Add partners & clients to your plan',
      ],
      items2: ['Up to 10 users online at the same time'],
      items3: ['Unlimited registered users in your plan'],
    },
  },
  {
    id: PlanId.StarterMonthly,
    type: 'monthly',
    name: 'Starter',
    nameDesc: 'Perfect for running virtual workshops in small teams.',
    price: '15€',
    rawPrice: 15,
    priceDesc: 'Per Month & User',
    buttonText: 'Buy now',
    buttonSubText: 'Cancel monthly',
    features: {
      name: 'Free features +',
      items: [
        'Upload limit 2GB',
        'Unlimited personal & shared spaces',
        'Basic onboarding',
        'Add partners & clients to your plan',
      ],
      items2: ['Up to 10 users online at the same time'],
      items3: ['Unlimited registered users in your plan'],
    },
  },
  {
    id: PlanId.PremiumMonthly,
    type: 'monthly',
    name: 'Business',
    nameDesc: 'Perfect for complex projects with more team members.',
    price: '25€',
    rawPrice: 25,
    priceDesc: 'Per Month & User',
    buttonText: 'Buy now',
    buttonSubText: 'Cancel monthly',
    features: {
      name: 'Starter features +',
      items: [
        'Upload limit 5GB',
        'Dedicated contact person',
        'Tailored onboarding',
        'Company expert training on request',
        'Custom brand design on request',
      ],
      items2: ['Up to 50 users online at the same time in VISPA'],
      items3: ['Unlimited registered users in your plan'],
    },
  },
  {
    id: PlanId.PremiumYearly,
    type: 'yearly',
    name: 'Business',
    nameDesc: 'Perfect for complex projects with more team members.',
    price: '20€',
    rawPrice: 20,
    buttonText: 'Buy now',
    buttonSubText: 'Cancel yearly',
    priceDesc: 'Per Month & User',
    recommended: true,
    features: {
      name: 'Starter features +',
      items: [
        'Upload limit 5GB',
        'Dedicated contact person',
        'Tailored onboarding',
        'Company expert training on request',
        'Custom brand design on request',
      ],
      items2: ['Up to 50 users online at the same time in VISPA'],
      items3: ['Unlimited registered users in your plan'],
    },
  },
  {
    id: 'id',
    type: 'yearly',
    name: 'Enterprise',
    nameDesc: 'Perfect for enterprises with individual needs.',
    price: 'Custom',
    priceDesc: 'Yearly offer',
    buttonText: 'Contact',
    buttonSubText: 'Get in touch',
    features: {
      name: 'Business features +',
      items: [
        'Unlimited upload',
        'Dedicated Customer Success Manager',
        'Corporate expert training',
        'Custom brand spaces',
        'Custom brand 3D & 2D elements',
        'Custom pre-build templates',
        'Multi factor authentification on request',
      ],
      items2: [,],
      items3: [,],
    },
  },
  {
    id: 'id',
    type: 'monthly',
    name: 'Enterprise',
    nameDesc: 'Perfect for enterprises with individual needs.',
    price: 'Custom',
    priceDesc: 'Individual',
    buttonText: 'Contact',
    buttonSubText: 'Get in touch',
    features: {
      name: 'Business features +',
      items: [
        'Unlimited upload',
        'Dedicated Customer Success Manager',
        'Corporate expert training',
        'Custom brand spaces',
        'Custom brand 3D & 2D elements',
        'Custom pre-build templates',
        'Multi factor authentification on request',
      ],
      items2: [,],
      items3: [,],
    },
  },
];
