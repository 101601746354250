import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

const initialState = {
  id: null,
  spaceId: null,
  portalOpen: false,
  mode: 'Note', // 'Text'
  sentImage: null,
  b64Image: '',
  portalZpos: 0,
  tweening: false,
  color: '#fff9b1',
  // color: 'blue',
  startAnimationFrame: false,
  agreeToTerms: false,
  name: '',
  customData: null,
  defaultPortalColor: '#F74E81',
};

export const portalSlice = createSlice({
  name: 'portal',
  initialState,
  reducers: {
    portalOpen: (state) => {
      state.portalOpen = true;
    },
    changeMode: (state, action: PayloadAction<string>) => {
      state.mode = action.payload;
    },
    setPortalId: (state, action) => {
      state.id = action.payload;
    },
    setSpaceId: (state, action) => {
      state.spaceId = action.payload;
    },
    setColor: (state, action) => {
      state.color = action.payload;
    },
    setZpos: (state, action) => {
      state.portalZpos = action.payload;
    },
    setTweening: (state, action) => {
      state.tweening = action.payload;
    },
    startAnimationFrame: (state, action) => {
      state.startAnimationFrame = action.payload;
    },
    agreeToTerms: (state) => {
      state.agreeToTerms = !state.agreeToTerms;
    },
    setPortalName: (state, action) => {
      state.name = action.payload;
    },
    setB64Image: (state, action) => {
      state.b64Image = action.payload;
    },
    setCustomData: (state, action) => {
      state.customData = action.payload;
    },
  },
});

export const {
  portalOpen,
  changeMode,
  setPortalId,
  setSpaceId,
  setColor,
  setZpos,
  setTweening,
  startAnimationFrame,
  agreeToTerms,
  setPortalName,
  setB64Image,
  setCustomData,
} = portalSlice.actions;

export const selectPortal = (state: RootState) => state.portal;

export default portalSlice.reducer;
