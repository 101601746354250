import React from 'react';
import { Layout } from 'antd';
import './SideBar.css';
import { useAppSelector } from '../../../app/hooks';
import { selectApp } from '../../App/appSlice';

const SideBar = ({ menu }) => {
  const app = useAppSelector(selectApp);
  if (app.hideSidebar) return;

  return (
    <Layout.Sider
      className='sidebar'
      width='390'
      breakpoint={'lg'}
      theme='light'
      collapsedWidth={0}
      trigger={null}
    >
      {menu}
    </Layout.Sider>
  );
};

export default SideBar;
