import React, { useEffect, useState } from 'react';
import { ScreenTypes } from './types/screen-types';
import { Button, Form, Input } from 'antd';
import { Auth } from 'aws-amplify';
import { openNotificationWithIcon } from '../../shared/helpers/notify';
import { passwordResetStage } from './types/password-reset-stage';
import { useQuery } from '../../shared/helpers/use-query';
import { Portals } from '../Portals/Portals';
import { Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { getAccessTokenSync } from '../Api/helpers/get-user-data';
import logo from '../../assets/images/VISPA_Logo_143x40.svg';
import { SignUp } from './components/SignUp';
import { SignIn } from './components/SignIn';
import { ButtonsType } from '../../shared/enums/buttons-type.enum';
import { PasswordInput } from '../../components/PasswordInput/PasswordInput';
import { getErrorWithText } from '../../shared/helpers/getErrorWithText';
import { CheckCircleOutlined } from '@ant-design/icons';
import { emailRegex } from '../../shared/regex/email-regex';
import { APPLE_AUTH_LINK, cognitoConfig, GOOGLE_AUTH_LINK } from '../../config/env';
import jwt_decode from 'jwt-decode';
import { useSessionMutation } from '../Api/org-members';
import { SuccessSSO } from './components/SuccessSSO';
import { AccountLinked } from './components/AccountLinked';
import { Webgl } from '../Webgl/Webgl';

const DEFAULT_TIMING_FOR_BLOCKING = 5000;
const cognitoIdServiceProvider = 'CognitoIdentityServiceProvider.';
const poolId = cognitoConfig().aws_user_pools_web_client_id;

function handleAuthRequestFromClient(sessionId, oauthtype) {
  localStorage.setItem('vispa-session', sessionId);
  localStorage.setItem('SSOProvider', oauthtype);
  const newHref = oauthtype === 'Google' ? GOOGLE_AUTH_LINK : APPLE_AUTH_LINK;
  window.location.href = newHref;
}

export const AuthComponent = ({ setLoggedIn }) => {
  const [screen, setScreen] = useState<ScreenTypes>();
  const [passwordResetStage, setPasswordResetStage] =
    useState<passwordResetStage>('initial');
  const [userEmail, setUserEmail] = useState<string>(null);
  const query = useQuery();
  const [_, setSearchParams] = useSearchParams();
  const [loginLoading, setLoginLoading] = useState(false);
  const [userCode, setUserCode] = useState(null);
  const [level, setLevel] = useState(0);
  const [resetCodeLoading, setResetCodeLoading] = useState<boolean>(false);
  const [wrongPasswordError, setWrongPasswordError] = useState<any>('');
  const [resetInstructionsLoading, setResetInstructionsLoading] =
    useState(false);
  const [resetInstructionsSuccess, setResetInstructionsSuccess] =
    useState(false);
  const [urlData, setUrlData] = useState('');
  const location = useLocation();

  const [updateSession] = useSessionMutation();
  const navigate = useNavigate();

  const authenticateUser = (idpResponse) => {
    const userMap = new Map();

    idpResponse.map((e) => {
      const data = e.split('=');
      userMap.set(data[0], data[1]);
    });

    const userObject: any = jwt_decode(userMap.get('id_token'));
    userMap.set('decodedIdToken', userObject);

    localStorage.setItem(
      cognitoIdServiceProvider +
        poolId +
        '.' +
        userMap.get('decodedIdToken').sub +
        '.idToken',
      userMap.get('id_token'),
    );
    localStorage.setItem(
      cognitoIdServiceProvider +
        poolId +
        '.' +
        userMap.get('decodedIdToken').sub +
        '.accessToken',
      userMap.get('access_token'),
    );
    localStorage.setItem(
      cognitoIdServiceProvider + poolId + '.LastAuthUser',
      userMap.get('decodedIdToken').sub,
    );
    localStorage.setItem('amplify-signin-with-hostedUI', 'false');

    const sessionId = localStorage.getItem('vispa-session');
    if (sessionId) {
      updateSession({
        sessionId,
        idToken: userMap.get('id_token'),
        accessToken: userMap.get('access_token'),
      });
      localStorage.removeItem('vispa-session');
      navigate('/success-sso');
    }
  };

  useEffect(() => {
    const authType: any = query.get('authtype');
    const email = query.get('email');
    const code = query.get('code');
    const oauthType = query.get('oauthtype');
    const sessionId = query.get('sessionId');

    const queryParams = new URLSearchParams(location.search);

    if (queryParams.get('mode') === ScreenTypes.App) {
      setScreen(ScreenTypes.App);
      sessionStorage.setItem('mode', ScreenTypes.App);
      return;
    }

    if (sessionStorage.getItem('mode') === ScreenTypes.App) {
      setScreen(ScreenTypes.App);
      return;
    }

    console.log('[queryParams]', queryParams);
    if (queryParams.has('space')) {
      const keys = [];
      // @ts-ignore
      for (let [key, value] of queryParams.entries()) {
        sessionStorage.setItem(key, value);
        keys.push(key);
      }
      sessionStorage.setItem('webglHelpersKeys', JSON.stringify(keys));
    }

    if (sessionId && oauthType)
      return handleAuthRequestFromClient(sessionId, oauthType);

    const urlData = document.URL?.split('#')[1]?.split('&');
    if (urlData) {
      if (urlData[0]?.includes('Social')) {
        setScreen(ScreenTypes.AccountLinked);
      } else {
        authenticateUser(urlData);
      }
    }
    if (email) setUserEmail(email.replace(' ', '+'));
    if (code) setUserCode(code);

    if (authType) {
      setScreen(authType);
    } else if (code) {
      setScreen(ScreenTypes.ResetPassword);
    } else if (email) {
      setScreen(ScreenTypes.SignUp);
    } else if (
      urlData &&
      urlData?.length > 0 &&
      urlData[0]?.includes('Social')
    ) {
      setUrlData(urlData[0].split('=')[1]);
      setScreen(ScreenTypes.AccountLinked);
    } else {
      sessionStorage.setItem('mode', 'default');
      setScreen(ScreenTypes.SignIn);
    }
  }, []);

  useEffect(() => {
    if (!getAccessTokenSync()) {
      const params = { authType: screen, code: userCode, email: userEmail };
      const pureParams = {};

      for (const [key, value] of Object.entries(params)) {
        if (value) {
          pureParams[key] = value;
        }
      }
      setSearchParams(pureParams);
    }
  }, [screen, userCode]);

  const handleSignIn = async ({ username, password }) => {
    setLoginLoading(true);
    try {
      const user = await Auth.signIn({
        username: username.toLowerCase(),
        password,
      });

      if (user.username) {
        await setLoggedIn(true);
        await window.location.reload();
      }
    } catch (e) {
      console.log('error ===>', e);
      if (e.message === 'Incorrect username or password.') {
        setWrongPasswordError('Enter a valid email address and password');
      } else if (e.message === 'Password attempts exceeded') {
        setWrongPasswordError('Password attempts exceeded');
      } else if (
        e.message.includes(
          'PreAuthentication failed with error Your password is expired',
        )
      ) {
        setWrongPasswordError(
          <span onClick={() => setScreen(ScreenTypes.ResetPassword)}>
            Your password is expired, please{' '}
            <u style={{ color: '#606EE0', cursor: 'pointer' }}>
              reset your password
            </u>
          </span>,
        );
      } else {
        setWrongPasswordError('');
        await openNotificationWithIcon({ message: e.message, type: 'error' });
      }
    } finally {
      setTimeout(() => {
        setLoginLoading(false);
      }, 2000);
    }
  };

  const handleResetPassword = async (
    values?: any,
    stage: string = 'initial',
  ) => {
    if (resetInstructionsSuccess) return;
    const email = values?.username ?? userEmail;
    if (stage === 'initial' && !userCode) {
      Auth.forgotPassword(email)
        .then(async () => {
          await openNotificationWithIcon({
            type: 'success',
            message: 'An email with instructions is sent',
          });

          setResetInstructionsSuccess(true);
          setResetInstructionsLoading(false);
          setTimeout(() => {
            setResetInstructionsSuccess(false);
          }, DEFAULT_TIMING_FOR_BLOCKING);
          setUserEmail(email);
          setPasswordResetStage('finish');
        })
        .catch((err) => console.log(err));
    }

    if (userCode) {
      try {
        Auth.forgotPasswordSubmit(userEmail, userCode, values.password)
          .then(async () => {
            await openNotificationWithIcon({
              type: 'success',
              message: 'Password has been updated.',
            });
            setPasswordResetStage('success');
          })
          .catch(async (err) => {
            openNotificationWithIcon({
              type: 'error',
              message: err.message,
            });
            console.log(err);
          });
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <>
      <img
        src={logo}
        alt='Vispa logo'
        style={{
          display: 'block',
          margin: '60px auto 30px auto',
          width: 180,
          height: 49,
          visibility: screen === ScreenTypes.App ? 'hidden' : 'visible',
        }}
      />

      <div className='whitebox-centered'>
        {screen === ScreenTypes.SignIn && (
          <SignIn
            wrongPasswordError={wrongPasswordError}
            handleSignIn={handleSignIn}
            setScreen={setScreen}
            loginLoading={loginLoading}
          />
        )}
        {screen === ScreenTypes.ConfirmationSuccess && (
          <SignIn
            wrongPasswordError={wrongPasswordError}
            handleSignIn={handleSignIn}
            setScreen={setScreen}
            loginLoading={loginLoading}
          />
        )}

        {screen === ScreenTypes.ResetPassword && (
          <div className='whitebox-popup' style={{ margin: '2rem 0 0 0' }}>
            {passwordResetStage === 'initial' && userCode && (
              <div>
                <div className='whitebox_Title'>
                  <h2 style={{ marginBottom: 30 }}>Set new password</h2>
                  <p
                    style={{ textAlign: 'left', paddingBottom: 21 }}
                    className='whitebox-popup-subhead'
                  >
                    <span className='lightgrey-inputLabel'>Work Email: </span>
                    <b>{userEmail}</b>
                  </p>
                  <Form
                    name='basic'
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={handleResetPassword}
                  >
                    <Form.Item
                      label='Password'
                      name='password'
                      style={{ marginBottom: 10 }}
                      rules={[
                        {
                          validator: async () => {
                            return level >= 0
                              ? Promise.resolve()
                              : Promise.reject('At least 8 characters');
                          },
                          message: getErrorWithText('Password is too weak'),
                        },
                      ]}
                    >
                      <PasswordInput onLevelChange={setLevel} />
                    </Form.Item>
                    <Form.Item
                      label='Confirm password'
                      name='confirmPassword'
                      dependencies={['password']}
                      style={{ marginBottom: 10 }}
                      rules={[
                        {
                          required: true,
                          message: getErrorWithText(
                            'Please confirm your password!',
                          ),
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error('Confirm password does not match'),
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>

                    <Button
                      type={ButtonsType.Primary}
                      htmlType='submit'
                      className='ant-btn-streched'
                    >
                      Change password
                    </Button>
                    <Button
                      type={ButtonsType.Link}
                      className='primary-text-button'
                      onClick={() => setUserCode(null)}
                    >
                      Back
                    </Button>
                  </Form>
                </div>
              </div>
            )}
            {passwordResetStage === 'initial' && !userCode && (
              <div>
                <div className='whitebox_Title'>
                  <h2>Reset password</h2>
                </div>
                <div>
                  <p className='whitebox-popup-subhead' style={{ margin: '0' }}>
                    Enter your email to receive instructions on how to reset
                    your password
                  </p>
                </div>
                <Form
                  name='basic'
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  onFinish={handleResetPassword}
                  autoComplete='off'
                >
                  <Form.Item
                    label='Work Email'
                    name='username'
                    style={{ marginBottom: 10 }}
                    rules={[
                      {
                        required: true,
                        message: getErrorWithText('Enter your email address'),
                        whitespace: true,
                      },
                      {
                        type: 'email',
                        message: getErrorWithText(
                          'Enter a valid email address',
                        ),
                      },
                      {
                        pattern: new RegExp(emailRegex),
                        message: getErrorWithText(
                          'Sorry, only letters (A-z), number (0-9), and periods (.) are allowed',
                        ),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <div>
                    <Button
                      type='primary'
                      htmlType='submit'
                      className='ant-btn-streched'
                      loading={resetCodeLoading}
                      onClick={() => {
                        setResetCodeLoading(true);
                        setTimeout(() => {
                          setResetCodeLoading(false);
                        }, 4000);
                      }}
                    >
                      Next
                    </Button>

                    <Button
                      type='link'
                      onClick={() => setScreen(ScreenTypes.SignIn)}
                      className='ant-btn-streched'
                      style={{ marginTop: 15 }}
                    >
                      Back
                    </Button>
                  </div>
                </Form>
              </div>
            )}

            {passwordResetStage === 'finish' && (
              <div>
                <div className='whitebox_Title'>
                  <h2>Reset password</h2>
                </div>
                <div>
                  <div>
                    <p
                      className='whitebox-popup-subhead'
                      style={{ margin: '0' }}
                    >
                      If your email <b>{userEmail}</b> is registered with us, we
                      will send you an email, with instructions on how to reset
                      your password. <br />
                      <br />
                      If you have not received it, please ask for it again and
                      check your email and spam folder.
                    </p>
                  </div>
                  <div>
                    <Button
                      className='ant-btn-streched'
                      type={ButtonsType.Primary}
                      onClick={() => {
                        if (resetInstructionsSuccess) return;
                        setResetInstructionsLoading(true);
                        setTimeout(
                          () => setResetInstructionsLoading(false),
                          DEFAULT_TIMING_FOR_BLOCKING,
                        );
                        handleResetPassword();
                      }}
                      loading={resetInstructionsLoading}
                    >
                      {resetInstructionsSuccess ? (
                        <span>
                          <CheckCircleOutlined style={{ color: '#52c41a' }} />{' '}
                          &nbsp;Instructions sent
                        </span>
                      ) : (
                        <span>Resend Instructions</span>
                      )}
                    </Button>
                    <Button
                      className='primary-text-button'
                      type={ButtonsType.Link}
                      onClick={() => setPasswordResetStage('initial')}
                    >
                      Back
                    </Button>
                  </div>
                </div>
              </div>
            )}

            {passwordResetStage === 'success' && (
              <div>
                <div className='whitebox_Title'>
                  <h2>Password changed successfully!</h2>
                </div>
                <p className='whitebox-popup-subhead'>
                  Work Email: <b>{userEmail}</b>
                </p>
                <Button
                  type={ButtonsType.Primary}
                  className='ant-btn-streched'
                  onClick={() => {
                    setScreen(ScreenTypes.SignIn);
                    setUserCode(null);
                    setUserEmail(null);
                    setPasswordResetStage('initial');
                  }}
                >
                  Back to login
                </Button>
              </div>
            )}
          </div>
        )}

        {screen === ScreenTypes.SignUp && <SignUp setScreen={setScreen} />}
        {screen === ScreenTypes.SuccessSSO && <SuccessSSO />}
        {screen === ScreenTypes.AccountLinked && (
          <AccountLinked data={urlData} />
        )}

        {screen === ScreenTypes.Portal && (
          <Routes>
            <Route
              path='/spaces/:spaceId/portals/:portalId'
              element={<Portals />}
            ></Route>
          </Routes>
        )}

        {screen === ScreenTypes.App && <Webgl />}
      </div>
    </>
  );
};
