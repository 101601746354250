import { gql } from '@apollo/client';

export const DELETE_ORG_MEMBERS = gql`
  mutation DeleteOrgMembers($orgId: String!, $userIds: [String!]!) {
    delete_OrganizationMembers(
      where: { UserId: { _in: $userIds }, OrganizationId: { _eq: $orgId } }
    ) {
      affected_rows
    }
  }
`;

export const CHANGE_MEMBER_TYPE = gql`
  mutation ChangeMemberType($userId: String!, $type: String!) {
    update_OrganizationMembers(
      where: { UserId: { _eq: $userId } }
      _set: { Type: $type }
    ) {
      affected_rows
    }
  }
`;
