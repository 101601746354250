// @ts-nocheck
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  selectPortal,
  setPortalId,
  setSpaceId,
  setZpos,
  setTweening,
  startAnimationFrame,
  setPortalName,
  setCustomData,
} from './portalSlice';
import { useParams } from 'react-router-dom';
import { TextEditor } from './components/TextEditor/TextEditor';
import * as THREE from 'three';
import { useThree, Canvas } from '@react-three/fiber';
import { useSpring, a } from '@react-spring/three';
import { config as springConfig } from '@react-spring/core';
import * as TWEEN from '@tweenjs/tween.js';
import { GET_PORTAL } from '../../graphql/get-portal';
import { useQuery } from '@apollo/client';
import { getAccessTokenSync } from '../Api/helpers/get-user-data';
import { rgbToHex } from '../../shared/helpers/hexToRGBA';
import { PortalNotFound } from './components/PortalNotFound/PortalNotFound';
import { Loading } from '../../components/Loading/Loading';

import './Portals.css';

export function Portals() {
  let { portalId, spaceId } = useParams();
  const dispatch = useAppDispatch();
  const portal = useAppSelector(selectPortal);

  const portalResponse = useQuery(GET_PORTAL, { variables: { portalId } });

  useEffect(() => {
    dispatch(setPortalName(portalResponse?.data?.Elements[0]?.Name));
    dispatch(setPortalId(portalId));
    dispatch(setSpaceId(spaceId));
    if (portalResponse?.data?.Elements[0]?.CustomData) {
      dispatch(
        setCustomData(JSON.parse(portalResponse.data.Elements[0].CustomData)),
      );
    }
  }, [portalResponse]);

  function changeZpos(zpos) {
    dispatch(setZpos(zpos));
  }

  function changeTweening(state: boolean) {
    dispatch(setTweening(state));
  }

  function setStartAnimationFrame(state: boolean) {
    dispatch(startAnimationFrame(state));
  }

  const accessToken = getAccessTokenSync();
  let canvasId = 'portal-canvas';
  if (accessToken) canvasId += '-auth';

  if (portalResponse.loading) return <Loading />;
  if (!portalResponse?.data?.Elements?.length) return <PortalNotFound />;

  return (
    <div className='portal'>
      <h1
        className='portal-name'
        style={{ textAlign: 'center', margin: '0 0 40px 0' }}
      >
        {portal.name}
      </h1>
      <TextEditor />
      {/*<ModeMenu />*/}

      <Canvas
        id={canvasId}
        camera={{ position: [0, 150, 0], near: 5, far: 1000, fov: 50 }}
        dpr={Math.max(window.devicePixelRatio, 2)}
        shadows
        style={{
          position: 'absolute',
          width: '100%',
          height: 1200,
          top: 0,
          left: 0,
          pointerEvents: 'none',
        }}
        linear
      >
        <ambientLight intensity={0.7} />
        <Light />

        <ThreePortal
          changeZpos={changeZpos}
          portal={portal}
          position={[0, 5, 0]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <ThreeNote
          setStartAnimationFrame={setStartAnimationFrame}
          changeTweening={changeTweening}
          portal={portal}
          rotation={[-Math.PI / 2, 0, 0]}
        />

        <mesh
          rotation={[-Math.PI / 2, 0, 0]}
          position={[0, -0.0, 0]}
          receiveShadow
        >
          <planeBufferGeometry args={[1000, 1000, 1, 1]} />
          <shadowMaterial transparent opacity={0.1} />
        </mesh>
      </Canvas>
    </div>
  );
}

// const ModeMenu = () => {
//     return (
//         <div className='Portal-Toolbar'>
//             <ModeButton mode='Text'>
//                 T
//             </ModeButton>
//             <ModeButton mode='Note'>
//                 N
//             </ModeButton>
//             {/*<ModeButton mode='Draw' disabled>*/}
//             {/*    */}
//             {/*</ModeButton>*/}
//             {/*<ModeButton mode='Upload' disabled>*/}
//             {/*    󰗼*/}
//             {/*</ModeButton>*/}
//         </div>
//     );
// };

// const ModeButton = (props) => {
//     const portals = useAppSelector(selectPortal);
//     const dispatch = useAppDispatch();
//
//     const selected = portals.mode == props.mode;
//
//     const handleClick = () => {
//         dispatch(changeMode(props.mode));
//     };
//
//     return (
//         <div className='Portal-Toolbar-Btn'
//             onClick={handleClick}
//             style={{
//                 cursor: selected ? 'auto' : 'pointer',
//                 fontFamily: 'icon',
//                 color: 'text',
//                 backgroundColor: selected ? 'active' : 'white',
//                 borderRight: '1px #F8F8F8',
//                 mr: props.last ? 0 : '2px',
//                 transition: 'background-color 0.15s',
//                 ':hover': {
//                     background: selected ? 'active' : 'white',
//                 },
//                 ...props.style,
//             }}
//         >
//             {props.children}
//         </div>
//     );
// };

const Light = () => {
  const dirLight = useRef();
  return (
    <directionalLight
      ref={dirLight}
      position={[-30, 50, -20]}
      castShadow
      shadow-mapSize-width={1024}
      shadow-mapSize-height={1024}
      shadow-camera-left={-75}
      shadow-camera-right={75}
      shadow-camera-top={75}
      shadow-camera-bottom={-75}
      shadow-camera-near={1}
      shadow-camera-far={150}
    />
  );
};

const ThreePortal = (props) => {
  const { camera } = useThree();
  const mesh = useRef();
  const { changeZpos, portal } = props;

  const circleShape = useMemo(() => {
    const arcShape = new THREE.Shape()
      .moveTo(50, 10)
      .absarc(10, 10, 40, 0, Math.PI * 2, false);

    const holePath = new THREE.Path()
      .moveTo(20, 10)
      .absarc(10, 10, 25, 0, Math.PI * 2, true);

    arcShape.holes.push(holePath);

    return arcShape;
  }, []);
  const geomRef = useRef();

  useLayoutEffect(() => {
    if (geomRef?.current) {
      // @ts-ignore
      geomRef.current.center();
    }
  }, [circleShape]);

  const rad = THREE.MathUtils.degToRad(camera.fov / 2);
  const g = camera.position.y * Math.tan(rad);
  const topPosition = -g + 17;

  changeZpos(topPosition);

  const { scale } = useSpring({
    scale: props.portal.tweening ? [0.18, 0.1, 0.18] : [0.1, 0.1, 0.1],
    config: springConfig.wobbly,
  });

  return (
    <a.group position={[0, 0, topPosition]} scale={scale}>
      <mesh
        {...props}
        ref={mesh}
        scale={1}
        position={[0, 5, 0]}
        castShadow
        receiveShadow
      >
        <extrudeBufferGeometry
          attach='geometry'
          ref={geomRef}
          args={[
            circleShape,
            {
              curveSegments: 24,
              steps: 1,
              depth: 10,
              bevelEnabled: false,
              bevelThickness: 1,
              bevelSize: 1,
              bevelOffset: 0,
            },
          ]}
        />
        <meshPhongMaterial
          color={
            !portal?.customData?.Colors?.length
              ? portal.defaultPortalColor
              : rgbToHex(portal.customData.Colors[0])
          }
        />
      </mesh>
    </a.group>
  );
};

function animate(time) {
  requestAnimationFrame(animate);
  TWEEN.update(time);
}

requestAnimationFrame(animate);

export function ThreeNote(props) {
  const { portal, changeTweening, setStartAnimationFrame } = props;
  const ref = useRef();

  const [tweening, setTweening] = useState(false);

  const setPlaneCoords = (coords) => {
    // @ts-ignore
    ref.current.position.y = coords.y;
    // @ts-ignore
    ref.current.position.z = coords.z;
    // @ts-ignore
    ref.current.material.opacity = coords.o;
    // @ts-ignore
    ref.current.scale.x = coords.s;
    // @ts-ignore
    ref.current.scale.y = coords.s;
    // @ts-ignore
    ref.current.scale.z = coords.s;
  };

  const setPlaneRot = (rot) => {
    // @ts-ignore
    ref.current.rotation.x = THREE.MathUtils.degToRad(rot.x);
    // @ts-ignore
    ref.current.rotation.z = THREE.MathUtils.degToRad(rot.z);
  };

  useEffect(() => {
    if (portal.startAnimationFrame) {
      let image = new Image();
      image.src = portal.b64Image;
      let texture = new THREE.Texture();
      texture.image = image;
      image.onload = function () {
        // @ts-ignore
        let mat = ref.current.material;
        mat.map = texture;
        mat.color = new THREE.Color(0xffffff);
        texture.needsUpdate = true;
        mat.needsUpdate = true;
      };

      setTweening(true);
      const coords = { o: 1, s: 1, z: 5.7, y: 0 }; // Start at (0, 0)
      const tween = new TWEEN.Tween(coords) // Create a new tween that modifies 'coords'.
        .to({ o: 1, z: 0, y: 30, s: 0.8 }, 400) // Move to (300, 200) in 1 second.
        .easing(TWEEN.Easing.Quadratic.In) // Use an easing function to make the animation smooth.
        .onUpdate(() => {
          setPlaneCoords(coords);
        })
        .onComplete(() => {
          changeTweening(true);
        });

      const tween2 = new TWEEN.Tween(coords);
      tween2
        .to({ o: 1, z: portal.portalZpos, y: 0, s: 0.005 }, 800) // Move to (300, 200) in 1 second.
        .easing(TWEEN.Easing.Quadratic.Out) // Use an easing function to make the animation smooth.
        .onUpdate(() => {
          setPlaneCoords(coords);
        })
        .onComplete(() => {
          setTweening(false);
          changeTweening(false);
        });
      tween.chain(tween2);

      tween.start();

      const rot = { x: -90, z: 0 }; // Start at (0, 0)
      const tweenR = new TWEEN.Tween(rot) // Create a new tween that modifies 'coords'.
        .to({ x: -90, z: -5 }, 600) // Move to (300, 200) in 1 second.
        .onUpdate(() => {
          setPlaneRot(rot);
        });

      const tweenR2 = new TWEEN.Tween(rot) // Create a new tween that modifies 'coords'.
        .to({ x: -140, z: 0 }, 300) // Move to (300, 200) in 1 second.
        .onUpdate(() => setPlaneRot(rot));

      tweenR.chain(tweenR2);
      tweenR.start();
      setStartAnimationFrame(false);
    }
  }, [portal.startAnimationFrame]);

  return (
    <mesh
      position={[0, 0, 5.7]}
      {...props}
      ref={ref}
      visible={tweening}
      castShadow
    >
      <boxBufferGeometry args={[57, 65, 0.01]} />
      {/*[width,height,length]*/}
      <meshBasicMaterial transparent={false} color='white' toneMapped={false} />
    </mesh>
  );
}
