import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export const getErrorWithText = (text) => {
  return (
    <span style={{ color: '#ff4d4f', fontSize: 14 }}>
      <ExclamationCircleOutlined />
      &nbsp;{text}
    </span>
  );
};
