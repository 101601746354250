import React from 'react';
import { Button } from 'antd';
import { ButtonsType } from '../../../shared/enums/buttons-type.enum';
import { APPLE_AUTH_LINK, GOOGLE_AUTH_LINK } from '../../../config/env';

interface IProps {
  data: any;
}

const blackFont = {
  color: '#474747',
};

const fieldsContainer = {
  marginTop: 20,
};

function getProviderUrlByName(name: string): string {
  switch (name) {
    case 'Google':
      return GOOGLE_AUTH_LINK;
    case 'SignInWithApple':
      return APPLE_AUTH_LINK;
  }
}

export function AccountLinked(props: IProps) {
  localStorage.setItem('PrivacyPolicy', 'true');

  const regex = /name=([^&]+).*email=([^&]+)/;
  const match = regex.exec(decodeURIComponent(props.data));

  let name, email;

  if (match) {
    name = decodeURIComponent(match[1].replace(/\+/g, ' ')).replace('.', '');
    email = decodeURIComponent(match[2]).replace(/\+$/, '');
  }

  function handleContinueToSignIn() {
    const provider = localStorage.getItem('SSOProvider');
    const newHref = getProviderUrlByName(provider);
    console.log(['newHref']);
    console.log(newHref);
    localStorage.removeItem('SSOProvider');
    window.location.href = newHref;
  }

  return (
    <div className='whitebox'>
      <div className='whitebox_Title'>
        <h2>Account Connected</h2>
        <div style={blackFont}>
          We use the following information to link your account with VISPA
        </div>

        <div style={fieldsContainer}>
          <p>
            Email <br /> <span style={blackFont}>{email}</span>
          </p>
          {name && !name.includes('undefined') && (
            <p>
              Name <br />
              <span style={blackFont}>{name}</span>
            </p>
          )}
        </div>

        <Button
          style={{ width: '100%' }}
          type={ButtonsType.Primary}
          onClick={() => handleContinueToSignIn()}
        >
          Continue to sign in
        </Button>
      </div>
    </div>
  );
}
