import { Button, Form, Modal, Popover, Radio, Select } from 'antd';
import { ButtonsType } from '../../../../shared/enums/buttons-type.enum';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { selectUserPlan, triesToMakePayment } from '../../userPlanSlice';
import { getPlanNameById } from '../CurrentPlan/helpers/getTeamType';
import React, { useEffect, useState } from 'react';
import { PlanId } from '../Plan/plan-id.enum';
import { SubscriptionsRequest } from '../../enums/subsriptions-request.enum';
import {
  useCheckoutMutation,
  usePaymentResourcesMutation,
} from '../../../Api/baseApi';
import { BillingPeriod } from './enums/billing-period.enum';
import { plans } from '../Plan/plans';
import { IconComponent } from '../../../../components/Icon/Icon';
import { getPlanPriceByPlanId } from '../Plan/helpers/get-plan-price-by-plan-id';
import { getUserData } from '../../../Api/helpers/get-user-data';
import { selectUser } from '../../../Profile/userSlice';

const { Option } = Select;

enum Months {
  One = 1,
  Twelve = 12,
}

const MAX_USO_FOR_SMALL_PLAN = 10;
const MAX_USO_FOR_BIG_PLAN = 50;

export function PlanModal({
  visible,
  setVisibleResources,
  setVisiblePlanModal,
}) {
  // STATE
  const userPlan = useAppSelector(selectUserPlan);
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  // API CALLS
  const [checkoutMutation] = useCheckoutMutation();
  const [paymentResources] = usePaymentResourcesMutation();

  // FORM
  const [planChangeForm] = Form.useForm();

  // COMPONENT STATE
  const [bigPlanSelected, setBigPlanSelected] = useState<boolean>(false);
  const [smallPlanSelected, setSmallPlanSelected] = useState<boolean>(false);
  const [checkoutUrl, setCheckoutUrl] = useState<string>('');
  const [price, setPrice] = useState<number>();
  const [calcValues, setCalcValues] = useState(null);

  // LIFECYCLE HOOKS
  useEffect(() => {
    if (userPlan.freeUserPlanSelected.includes('starter'))
      setSmallPlanSelected(true);
    if (userPlan.freeUserPlanSelected.includes('premium'))
      setBigPlanSelected(true);
  }, [userPlan.freeUserPlanSelected]);

  useEffect(() => {
    setPrice(userPlan?.value?.subscription_items[0].amount / 100);
  }, [userPlan]);

  useEffect(() => {
    if (bigPlanSelected) {
      setCalcValues({
        ...calcValues,
        months: !userPlan.triesToMakePayment ? Months.Twelve : Months.One,
      });
      planChangeForm.setFieldsValue({ billingPeriod: BillingPeriod.Yearly });
      planChangeForm.validateFields().then(({ USO }) => {
        handleFormChange(null, { USO, billingPeriod: BillingPeriod.Yearly });
      });

      dispatch(triesToMakePayment(false));
    }

    if (smallPlanSelected) {
      setCalcValues({
        ...calcValues,
        months: !userPlan.triesToMakePayment ? Months.Twelve : Months.One,
      }); // Check if user clicked on [handlePlanClickForFreeUser] function in Payment component

      dispatch(triesToMakePayment(false));

      planChangeForm.validateFields().then(({ USO }) => {
        if (USO > MAX_USO_FOR_SMALL_PLAN) USO = MAX_USO_FOR_SMALL_PLAN;
        planChangeForm.setFieldsValue({ USO });
        handleFormChange(null, {
          USO,
          billingPeriod: !userPlan.triesToMakePayment
            ? BillingPeriod.Yearly
            : BillingPeriod.Monthly,
        });
      });
    }
  }, [bigPlanSelected, smallPlanSelected]);

  // MAIN LOGIC

  function getUSOOptions(itemPriceId) {
    let subsCount =
      (itemPriceId && itemPriceId === PlanId.PremiumYearly) || bigPlanSelected
        ? MAX_USO_FOR_BIG_PLAN
        : MAX_USO_FOR_SMALL_PLAN;
    subsCount = smallPlanSelected ? MAX_USO_FOR_SMALL_PLAN : subsCount;
    return Array.from({ length: subsCount }, (_, i) => i + 1).map((i) => (
      <Option value={i}>{i}</Option>
    ));
  }

  function getUserPlanByBillingPeriod(billingPeriod) {
    if (
      (!bigPlanSelected &&
        userPlan?.value?.subscription_items[0].item_price_id !==
          PlanId.PremiumYearly) ||
      smallPlanSelected
    ) {
      return billingPeriod === BillingPeriod.Yearly
        ? PlanId.StartedYearly
        : PlanId.StarterMonthly;
    }
    return billingPeriod === BillingPeriod.Yearly
      ? PlanId.PremiumYearly
      : PlanId.PremiumMonthly;
  }

  function getPriceOfSubscriptionByMonth(billingPeriod) {
    const uso = calcValues?.USO
      ? calcValues.USO
      : userPlan?.value?.subscription_items[0].quantity;
    const up = getUserPlanByBillingPeriod(billingPeriod);
    return uso * getPlanPriceByPlanId(up);
  }

  function handleFormChange(_, { USO = 1, billingPeriod }) {
    const plan = plans.find(
      (p) => p.id === getUserPlanByBillingPeriod(billingPeriod),
    );
    const months: number =
      billingPeriod === BillingPeriod.Monthly ? Months.One : Months.Twelve;
    setCalcValues({ USO, price: plan.rawPrice, months });
    setPrice(plan.rawPrice * USO * months);
  }

  // ----------------- Logic for purchasing or updating existing subscription START

  function resolvePlanHandler() {
    return userPlan.freeUserPlanSelected
      ? handlePlanPurchase()
      : handlePlanChange();
  }

  function handlePlanChange() {
    planChangeForm.validateFields().then(({ USO, billingPeriod }) => {
      let plan_id = getUserPlanByBillingPeriod(billingPeriod);
      paymentResources({
        requestType: SubscriptionsRequest.ChangeSubscription,
        plan_id,
        USO,
      })
        .unwrap()
        .then((fulfilled) => setCheckoutUrl(fulfilled.data.hosted_page.url))
        .catch(console.error);
    });
  }

  async function handlePlanPurchase() {
    planChangeForm.validateFields().then(async ({ USO = 1, billingPeriod }) => {
      let plan_id = getUserPlanByBillingPeriod(billingPeriod);
      const userData = await getUserData();
      checkoutMutation({
        plan_id,
        email: userData.attributes.email,
        firstName: user.GivenName,
        lastName: user.FamilyName,
        quantity: USO,
      })
        .unwrap()
        .then((fulfilled) => setCheckoutUrl(fulfilled.url))
        .catch((rejected) => console.error(rejected));
    });
  }

  // -----------------  Logic for purchasing or updating existing subscription END

  return (
    <Modal
      footer={[]}
      visible={visible}
      className='whitebox-centered'
      onCancel={() => {
        setVisiblePlanModal(false);
        window.location.reload();
      }}
    >
      {!checkoutUrl ? (
        <div className='whitebox-popup'>
          <div className='whitebox_Title'>
            <h2>Order</h2>
          </div>
          <div className='whitebox-plan'>
            <h2>
              {bigPlanSelected
                ? getPlanNameById(PlanId.PremiumYearly)
                : smallPlanSelected
                ? getPlanNameById(PlanId.StartedYearly)
                : getPlanNameById(
                    userPlan?.value?.subscription_items[0]?.item_price_id,
                  )}
            </h2>
          </div>

          <Form
            onValuesChange={handleFormChange}
            form={planChangeForm}
            name='form_in_modal'
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            autoComplete='off'
            initialValues={{
              billingPeriod: userPlan?.value?.billing_period_unit,
              USO: userPlan?.value?.subscription_items[0].quantity,
            }}
          >
            <Form.Item
              name='USO'
              className='form-USO'
              label={
                <div>
                  Users simultaneously online
                  <Popover
                    placement='topLeft'
                    content='Number of users who can simultaneously join the space'
                    trigger='hover'
                  >
                    <IconComponent
                      props={{
                        iconName: 'icon-Info_Circle',
                        style: { fontSize: '20px', paddingLeft: '5px' },
                      }}
                    />
                  </Popover>
                </div>
              }
            >
              <Select defaultValue={1}>
                {getUSOOptions(
                  userPlan?.value?.subscription_items[0]?.item_price_id,
                )}
              </Select>
            </Form.Item>

            {smallPlanSelected && (
              <Form.Item name='bigPlanSelected' className='form-USO'>
                <p className='leftalign-text'>
                  Need more users?{' '}
                  <a
                    className='text-btn'
                    onClick={async () => {
                      setBigPlanSelected(true);
                      setSmallPlanSelected(false);
                    }}
                  >
                    Choose the Business Plan
                  </a>
                </p>
              </Form.Item>
            )}

            {!smallPlanSelected &&
              userPlan?.value?.subscription_items[0].item_price_id !==
                PlanId.PremiumYearly &&
              !bigPlanSelected && (
                <Form.Item name='bigPlanSelected' className='form-USO'>
                  <p className='leftalign-text'>
                    Need more users?{' '}
                    <a
                      className='text-btn'
                      onClick={async () => {
                        setBigPlanSelected(true);
                        setSmallPlanSelected(false);
                      }}
                    >
                      Choose the Business Plan
                    </a>
                  </p>
                </Form.Item>
              )}

            {bigPlanSelected && (
              <Form.Item name='smallPlanSelected' className='form-USO'>
                <p className='leftalign-text'>
                  Need less users?{' '}
                  <a
                    className='text-btn'
                    onClick={async () => {
                      setSmallPlanSelected(true);
                      setBigPlanSelected(false);
                    }}
                  >
                    Choose the Starter Plan
                  </a>
                </p>
              </Form.Item>
            )}

            {!bigPlanSelected &&
              userPlan?.value?.subscription_items[0].item_price_id ===
                PlanId.PremiumYearly &&
              !smallPlanSelected && (
                <Form.Item name='smallPlanSelected' className='form-USO'>
                  <p className='leftalign-text'>
                    Need less users?{' '}
                    <a
                      className='text-btn'
                      onClick={async () => {
                        setSmallPlanSelected(true);
                        setBigPlanSelected(false);
                      }}
                    >
                      Choose the Starter Plan
                    </a>
                  </p>
                </Form.Item>
              )}

            <Form.Item
              name='streaming'
              label={
                <div>
                  Streaming option
                  <Popover
                    placement='topLeft'
                    content='Number of users who can watch the video stream online'
                    trigger='hover'
                  >
                    <IconComponent
                      props={{
                        iconName: 'icon-Info_Circle',
                        style: { fontSize: '20px', paddingLeft: '5px' },
                      }}
                    />
                  </Popover>
                </div>
              }
            >
              <Select disabled></Select>
            </Form.Item>

            <Form.Item name='billingPeriod' label='Billing period'>
              <Radio.Group defaultValue={BillingPeriod.Monthly}>
                <div className='Radio-btn-row'>
                  <Radio value={BillingPeriod.Yearly}>Yearly</Radio>
                  <p>
                    {getPriceOfSubscriptionByMonth(BillingPeriod.Yearly)}
                    &nbsp;€/month
                  </p>
                </div>
                <div className='Radio-btn-row'>
                  <Radio value={BillingPeriod.Monthly}>Monthly</Radio>
                  <p>
                    {getPriceOfSubscriptionByMonth(BillingPeriod.Monthly)}
                    &nbsp;€/month
                  </p>
                </div>
                )
              </Radio.Group>
            </Form.Item>

            <p className='order-price'>
              Total: {price}€
              {calcValues && (
                <p className='order-calc'>
                  {calcValues.USO} USO x {calcValues.price}
                  {userPlan?.value?.currency_code || '€'} x {calcValues.months}{' '}
                  month(s)
                </p>
              )}
            </p>

            {/*Is shown only for users who already have subscription*/}
            {!userPlan.freeUserPlanSelected && (
              <p className='centered-text' style={{ marginBottom: '0' }}>
                <a
                  className='text-btn'
                  onClick={() => setVisibleResources(true)}
                >
                  Change my payment method
                </a>
              </p>
            )}

            <Button
              className='ant-btn-streched'
              key='submit'
              type={ButtonsType.Primary}
              onClick={resolvePlanHandler}
            >
              Next
            </Button>
            <div style={{ textAlign: 'center' }}>
              <Button
                className='centered-text-button'
                key='back'
                type={ButtonsType.Link}
                onClick={() => {
                  setVisiblePlanModal(false);
                  window.location.reload();
                }}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </div>
      ) : (
        <iframe
          src={checkoutUrl}
          frameBorder='0'
          scrolling='no'
          className='order-card'
        >
          Browser not compatible.
        </iframe>
      )}
    </Modal>
  );
}
