import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

const initialState = {
  value: null,
};

export enum UserTypes {
  Free = 'User',
  Member = 'Member',
  Admin = 'Admin',
  Owner = 'Owner',
}

export const UserTypesColor = new Map<UserTypes, string>([
  [UserTypes.Owner, '#00D9C3'],
  [UserTypes.Admin, '#FF4444'],
  [UserTypes.Member, '#44AEFF'],
]);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    saveUser: (state, action: PayloadAction<any>) => {
      state.value = action.payload;
    },
  },
});

export const { saveUser } = userSlice.actions;

export const selectUser = (state: RootState) => state.user.value;

export default userSlice.reducer;
