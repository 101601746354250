import React from 'react';
import { Menu } from 'antd';

export const TopicMenu = ({ topics, selectedKey, changeSelectedKey }) => {
  const styledTopics = [];
  topics.forEach(
    (topic, index) =>
      topic &&
      styledTopics.push(
        <Menu.Item key={index} onClick={changeSelectedKey}>
          {topic}
        </Menu.Item>,
      ),
  );

  return (
    <Menu
      style={{ borderRadius: 15 }}
      mode='inline'
      selectedKeys={[selectedKey?.toString()]}
    >
      {styledTopics}
    </Menu>
  );
};
