import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

const initialState = {
  hideNavbar: false,
  hideSidebar: false,
  linkParams: null,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    hideSidebar: (state, action: PayloadAction<any>) => {
      state.hideSidebar = true;
    },
  },
});

export const { hideSidebar } = appSlice.actions;

export const selectApp = (state: RootState) => state.app;

export default appSlice.reducer;
