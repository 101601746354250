import { gql } from '@apollo/client';

export const GET_USER_BY_EMAIL = gql`
  query User($email: String!) {
    Users(where: { Email: { _eq: $email } }) {
      Id
      Sub
      Email
      FamilyName
      GivenName
      OrganizationName
      TrackingEnabled
      NewsEnabled
      ChargebeeId
      OrganizationMemberships {
        Type
      }
    }
  }
`;
