import { gql } from '@apollo/client';

// TODO: change $adminId to $userId as admin is redundant (update for owners and admins)
export const GET_COMPANY = gql`
  query GetCompany($adminId: String!) {
    OrganizationMembers(where: { UserId: { _eq: $adminId } }) {
      Organization {
        AdminId
        Name
        City
        Country
        HouseNumber
        Id
        PostalCode
        Streaming
        Street
        CreatedAt
        Admin {
          Email
          GivenName
          FamilyName
        }
      }
    }
  }
`;

export const GET_COMPANY_BY_MEMBER = gql`
  query GetOrgMembers($userId: String!) {
    OrganizationMembers(where: { UserId: { _eq: $userId } }) {
      Organization {
        Admin {
          FamilyName
          GivenName
          Email
          OrganizationName
          Id
        }
        AdminId
        Id
        Members
        Name
        SubscriptionPlan
        ConcurrentUsers
      }
    }
  }
`;

export const LEAVE_CURRENT_SUBSCRIPTION = gql`
  mutation LeaveCurrentSubscription($userId: String!) {
    delete_OrganizationMembers(where: { UserId: { _eq: $userId } }) {
      affected_rows
    }
  }
`;

export const CHANGE_USER_TYPE = gql`
  mutation ChangeUserType($userId: String!, $type: String!) {
    update_Users(where: { Id: { _eq: $userId } }, _set: { Type: $type }) {
      affected_rows
    }
  }
`;
