import { TopicMenu } from './TopicMenu/TopicMenu';
import { useEffect, useState } from 'react';
import { Button, Layout } from 'antd';
import SideBar from './Sidebar/Sidebar';
import { Payment } from '../Payment/Payment';
import { Profile } from '../Profile/Profile';
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { mapPathToTopic } from './helpers/map-path-to-topic';
import { getUserData } from '../Api/helpers/get-user-data';
import { NavBar } from './Navbar/Navbar';
import { Portals } from '../Portals/Portals';
import { IconComponent } from '../../components/Icon/Icon';
import { Download } from '../Download/Download';
import { useMutation, useQuery } from '@apollo/client';
import { GET_USER_BY_EMAIL } from '../../graphql/get-user-by-email.query';
import { saveUser, selectUser, UserTypes } from '../Profile/userSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { SuccessSSO } from '../Auth/components/SuccessSSO';
import { selectApp } from '../App/appSlice';
import { UPDATE_USER_PRIVACY } from '../../graphql/update-user-privacy';
import { Webgl } from '../Webgl/Webgl';
import { ButtonsType } from '../../shared/enums/buttons-type.enum';

const BookDemoHref =
  'https://meetings.hubspot.com/manuel176/discovery-call-termin';

export function NavPanel() {
  const app = useAppSelector(selectApp);
  const location = useLocation();
  const user = useAppSelector(selectUser);

  const [updateUserPrivacy] = useMutation(UPDATE_USER_PRIVACY);

  const [selectedKey, setSelectedKey] = useState<number>(
    mapPathToTopic(location.pathname),
  );
  const dispatch = useAppDispatch();

  const userRequest = useQuery(GET_USER_BY_EMAIL, {
    variables: { email: getUserData().attributes.email ?? '' },
  });

  const NewsEnabled = localStorage.getItem('NewsEnabled');
  const TrackingEnabled = localStorage.getItem('TrackingEnabled');

  if (NewsEnabled && TrackingEnabled) {
    updateUserPrivacy({
      variables: {
        sub: getUserData().username,
        TrackingEnabled: TrackingEnabled === 'true',
        NewsEnabled: NewsEnabled === 'true',
      },
    })
      .then(() => {
        localStorage.removeItem('NewsEnabled');
        localStorage.removeItem('TrackingEnabled');
      })
      .catch((e) => console.error(e));
  }

  const navigate = useNavigate();

  useEffect(() => {
    const space = sessionStorage.getItem('space');
    const keys = sessionStorage.getItem('webglHelpersKeys');

    let link = `/app?`;
    if (space && keys) {
      const keysArr = JSON.parse(keys);
      sessionStorage.removeItem('webglHelpersKeys');
      // check for all dynamic query params and create a navigate link for them. them clear he sessionStorage.
      for (let param of keysArr) {
        link += `&${param}=${sessionStorage.getItem(param)}`;
        sessionStorage.removeItem(param);
      }
      navigate(link);
    }

    if (space && !keys) {
      navigate(link + `space=${space}`);
    }
  }, []);

  useEffect(() => {
    if (!userRequest?.data?.Users) return;
    const [userData] = userRequest.data.Users;
    if (!userData) return;
    dispatch(
      saveUser({
        ...userData,
        Type: userData?.OrganizationMemberships[0]?.Type || UserTypes.Free,
      }),
    );
  }, [userRequest]);

  useEffect(() => {
    setSelectedKey(mapPathToTopic(location.pathname));
  }, [location.pathname]);

  // check if

  const topics: any[] = [
    <div className='Nav-btn'>
      <IconComponent
        props={{ iconName: 'icon-Download', style: { fontSize: 30 } }}
      />{' '}
      <Link to='/'>Get VISPA</Link>
    </div>,

    <div className='Nav-btn'>
      <IconComponent
        props={{ iconName: 'icon-User', style: { fontSize: 30 } }}
      />{' '}
      <Link to='/profile'>Profile</Link>
    </div>,
    <div className='Nav-btn'>
      <IconComponent
        props={{ iconName: 'icon-Creditcard', style: { fontSize: 30 } }}
      />{' '}
      <Link to='/payment?component=payment'>
        {user?.Type === UserTypes.Owner ||
        user?.Type === UserTypes.Admin ||
        user?.Type === UserTypes.Member
          ? 'My subscription'
          : 'My plan'}
      </Link>
    </div>,
    (user?.Type === UserTypes.Owner || user?.Type === UserTypes.Admin) && (
      <div className='Nav-btn' style={{ left: 40, position: 'relative' }}>
        <IconComponent
          props={{ iconName: 'icon-Group_Work', style: { fontSize: 30 } }}
        />{' '}
        <Link to='/payment?component=members'>Members</Link>
      </div>
    ),
    <div>
      <Button id='streaming-button' type={ButtonsType.Default}>
        <a href={BookDemoHref} target='_blank'>
          Book a Demo
        </a>
      </Button>
    </div>,
    <div id='legalLinks'>
      &nbsp;
      <a href='https://www.vispa.io/imprint' target='_blank'>
        Imprint
      </a>
    </div>,
  ];

  const changeSelectedKey = (event) => {
    const key = event.key;
    setSelectedKey(key);
  };

  const Menu = (
    <TopicMenu
      topics={topics}
      selectedKey={selectedKey}
      changeSelectedKey={changeSelectedKey}
    />
  );

  return (
    <div className='App'>
      <NavBar loggedIn={true} setScreen={null} menu={Menu} />
      <Layout>
        <SideBar menu={Menu} />
        <Layout.Content color='white' className='content'>
          <Routes>
            <Route path='/app' element={<Webgl />} />
            <Route path='/' element={<Download />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/payment/' element={<Payment />} />
            <Route
              path='/spaces/:spaceId/portals/:portalId'
              element={<Portals />}
            />
            <Route path='/success-sso' element={<SuccessSSO />} />
          </Routes>
        </Layout.Content>
      </Layout>
    </div>
  );
}
