import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface UserPlanState {
  value: any;
  status: 'idle' | 'loading' | 'failed';
  freeUserPlanSelected: string;
  triesToMakePayment: boolean; // it's enabled only for the first time user clicks on some plan in plans modal
}

const initialState: UserPlanState = {
  value: null,
  status: 'idle',
  freeUserPlanSelected: '',
  triesToMakePayment: true,
};

export const userPlanSlice = createSlice({
  name: 'userPlan',
  initialState,
  reducers: {
    saveUserPlan: (state, action: PayloadAction<number>) => {
      state.value = action.payload;
    },
    saveFreeUserPlanSelectd: (state, action: PayloadAction<string>) => {
      state.freeUserPlanSelected = action.payload;
    },
    triesToMakePayment: (state, action: PayloadAction<boolean>) => {
      state.triesToMakePayment = action.payload;
    },
  },
});

export const { saveUserPlan, saveFreeUserPlanSelectd, triesToMakePayment } =
  userPlanSlice.actions;

export const selectUserPlan = (state: RootState) => state.userPlan;

export default userPlanSlice.reducer;
