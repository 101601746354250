import React, { useEffect, useState } from 'react';
import './App.less';
import Amplify, { Auth } from 'aws-amplify';
import { BrowserRouter } from 'react-router-dom';
import { NavPanel } from './features/NavPanel/NavPanel';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  concat,
  HttpLink,
  InMemoryCache,
  NextLink,
  Operation,
} from '@apollo/client';
import { AuthComponent } from './features/Auth/Auth';
import { onError } from '@apollo/client/link/error';
import { cognitoConfig, graphqlUri } from './config/env';
import { getIdTokenSync } from './features/Api/helpers/get-user-data';

Amplify.configure(cognitoConfig());

const App = () => {
  const [loggedIn, setLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    assessLoggedInState();
  }, []);

  const httpLink = new HttpLink({ uri: graphqlUri });
  const authMiddleware = new ApolloLink(
    (operation: Operation, forward: NextLink) => {
      if (getIdTokenSync()) {
        operation.setContext({
          headers: { Authorization: `Bearer ${getIdTokenSync()}` },
        });
      }
      return forward(operation);
    },
  );

  const logoutLink = onError(({ networkError }) => {
    // @ts-ignore
    if (networkError?.statusCode === 401) window.location.reload();
  });

  const client = new ApolloClient({
    link: logoutLink.concat(concat(authMiddleware, httpLink)),
    cache: new InMemoryCache(),
  });

  const assessLoggedInState = () => {
    try {
      Auth.currentAuthenticatedUser()
        .then(() => setLoggedIn(true))
        .catch(() => setLoggedIn(false));
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <ApolloProvider client={client}>
        <BrowserRouter>
          {loggedIn ? (
            <NavPanel />
          ) : (
            <AuthComponent setLoggedIn={setLoggedIn} />
          )}
        </BrowserRouter>
      </ApolloProvider>
    </div>
  );
};

export default App;
