export const env: string | 'production' | 'develop' | 'stage' =
  process.env.REACT_APP_BUILD_ENV || 'develop'; // change this to 'production' for debugging in prod mode

// API GATEWAY
export const apiGatewayUrlDevEnv = () => {
  if (env === 'production' || env === 'stage')
    return 'https://uhe6aghf4g.execute-api.eu-central-1.amazonaws.com/Production/';
  return 'https://uhe6aghf4g.execute-api.eu-central-1.amazonaws.com/Development_Active/';
};

export const apiGatewayUrlProdEnv = () => {
  if (env === 'production' || env === 'stage')
    return 'https://wagg0jmai3.execute-api.eu-central-1.amazonaws.com/Production';
  return 'https://uhe6aghf4g.execute-api.eu-central-1.amazonaws.com/Development_Active/';
};
// COGNITO
export const cognitoConfig = () => {
  if (env === 'production' || env === 'stage') {
    return {
      aws_project_region: 'eu-central-1',
      aws_cognito_identity_pool_id:
        'eu-central-1:69ceb6ca-91f3-4855-a0c5-e3d775b64030',
      aws_cognito_region: 'eu-central-1',
      aws_user_pools_id: 'eu-central-1_f7pUJ9gqY',
      aws_user_pools_web_client_id: '5k7lrkdhkc4d5qh6s65sdd9glm',
      oauth: {},
    };
  }
  return {
    aws_project_region: 'eu-central-1',
    aws_cognito_identity_pool_id:
      'eu-central-1:48adb6a8-95fa-48d9-9bac-09ddbe7ef10d',
    aws_cognito_region: 'eu-central-1',
    aws_user_pools_id: 'eu-central-1_lAziRFVXh',
    aws_user_pools_web_client_id: '7iopbuqlahd636dsffjm52svlb',
    oauth: {},
  };
};
// HASURA
export const graphqlUri =
  env === 'production' || env === 'stage'
    ? 'https://vispaproduction.hasura.app/v1/graphql'
    : 'https://vispa.hasura.app/v1/graphql';

export const vispaBuild =
  env === 'production' || env === 'stage'
    ? 'https://vispa-prod-app.s3.eu-central-1.amazonaws.com/Windows/Launcher/VISPA.zip'
    : 'https://vispa-dev-app.s3.eu-central-1.amazonaws.com/Windows/Launcher/VISPA.zip';

export const vispaMacBuild = 'https://apps.apple.com/us/app/vispa/id1628197529';

export const GOOGLE_AUTH_LINK =
  env === 'production' || env === 'stage'
    ? 'https://auth.myvispa.io/oauth2/authorize?identity_provider=Google&redirect_uri=https://myvispa.io&response_type=TOKEN&client_id=5k7lrkdhkc4d5qh6s65sdd9glm&scope=email openid'
    : 'https://vispa-dev.auth.eu-central-1.amazoncognito.com/oauth2/authorize?identity_provider=Google&redirect_uri=https://develop.myvispa.io&response_type=TOKEN&client_id=7iopbuqlahd636dsffjm52svlb&scope=email openid';
export const APPLE_AUTH_LINK =
  env === 'production' || env === 'stage'
    ? 'https://auth.myvispa.io/oauth2/authorize?identity_provider=SignInWithApple&redirect_uri=https://myvispa.io&response_type=TOKEN&client_id=5k7lrkdhkc4d5qh6s65sdd9glm&scope=email openid'
    : 'https://vispa-dev.auth.eu-central-1.amazoncognito.com/oauth2/authorize?identity_provider=SignInWithApple&redirect_uri=https://develop.myvispa.io&response_type=TOKEN&client_id=7iopbuqlahd636dsffjm52svlb&scope=email openid';

export function getWebGlBuild() {
  if (env === 'production')
    return 'https://d368o2b196qunl.cloudfront.net/WebGL/';
  if (env === 'stage')
    return 'https://d368o2b196qunl.cloudfront.net/WebGL_Stage/';
  if (env === 'develop') return 'https://d1us4ey8bpxxux.cloudfront.net/';
}
