import { Button, Card, Divider, Modal } from 'antd';
import { getPlanNameById } from './helpers/getTeamType';
import { formatDateString } from '../../../../shared/helpers/formatDateString';
import { useAppSelector } from '../../../../app/hooks';
import { selectUserPlan } from '../../userPlanSlice';
import { ButtonsType } from '../../../../shared/enums/buttons-type.enum';
import React, { useState } from 'react';
import { usePaymentResourcesMutation } from '../../../Api/baseApi';
import { SubscriptionsRequest } from '../../enums/subsriptions-request.enum';
import { openNotificationWithIcon } from '../../../../shared/helpers/notify';
import { PlanModal } from '../PlanModal/PlanModal';
import {
  selectUser,
  UserTypes,
  UserTypesColor,
} from '../../../Profile/userSlice';
import { LeaveSubscriptionModal } from '../LeaveSubscriptionModal/LeaveSubscriptionModal';
import { AskForMoreModal } from '../AskForMoreModal/AskForMoreModal';

interface IProps {
  setVisiblePlans: any;
  setVisiblePlanModal: any;
  visiblePlanModal: any;
  setVisibleResources: any;
}

export function CurrentPlan({
  setVisiblePlans,
  setVisiblePlanModal,
  visiblePlanModal,
  setVisibleResources,
}: IProps) {
  const userPlan = useAppSelector(selectUserPlan);
  const user = useAppSelector(selectUser);

  const [cancelPlanModal, setCancelPlanModal] = useState<boolean>(false);
  const [visibleAskForMoreModal, setVisibleAskForMoreModal] =
    useState<boolean>(false);
  const [visibleLeaveSubscriptionModal, setVisibleLeaveSubscriptionModal] =
    useState<boolean>(false);

  const [paymentResources] = usePaymentResourcesMutation();

  async function handleCancelPlanClick() {
    paymentResources({ requestType: SubscriptionsRequest.PauseSubscription })
      .unwrap()
      .then((fulfilled) => {
        openNotificationWithIcon({
          message: fulfilled.message,
          type: 'success',
        });
        setCancelPlanModal(false);

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((rejected) => console.error(rejected));
  }

  return (
    <div>
      {user?.Type === UserTypes.Admin && !!userPlan?.value && (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            gap: 30,
          }}
        >
          <Card className='greybox plan-card-info' bordered={false}>
            <div>
              <h3 className='graybox-title'>Active Plan</h3>
              <h4 className='graybox-sub-title' style={{ height: 30 }}>
                <span style={{ color: UserTypesColor.get(user?.Type) }}>
                  {user?.Type}
                </span>{' '}
                of the <b>{userPlan?.value?.Name}</b> Subscription.
              </h4>
              <h2 className='graybox-content'>
                {getPlanNameById(userPlan.value.SubscriptionPlan)}&nbsp;
              </h2>
            </div>
            <Divider className='graybox-divider' />
            <Button
              type={ButtonsType.Link}
              id='btn-plan-option'
              onClick={() => setVisibleLeaveSubscriptionModal(true)}
            >
              Leave current subscription
            </Button>
          </Card>

          <Card className='greybox plan-card-info' bordered={false}>
            <div>
              <h3 className='graybox-title'>Concurrent Users</h3>
              <h4 className='graybox-sub-title' style={{ height: 30 }}>
                Users online at the same time
              </h4>
              <h2 className='graybox-content'>
                {userPlan?.value?.ConcurrentUsers}
              </h2>
            </div>
            <Divider className='graybox-divider' />
            <Button
              type={ButtonsType.Link}
              id='btn-uso-option'
              onClick={() => setVisibleAskForMoreModal(true)}
              disabled
            >
              Ask for more
            </Button>
          </Card>
        </div>
      )}
      {user?.Type === UserTypes.Member && userPlan?.value && (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            gap: 30,
          }}
        >
          <Card className='greybox plan-card-info' bordered={false}>
            <div>
              <h3 className='graybox-title'>Active Plan</h3>
              <h4 className='graybox-sub-title'>
                <span style={{ color: UserTypesColor.get(user?.Type) }}>
                  {user?.Type}
                </span>{' '}
                of the <b>{userPlan?.value?.Name}</b> Subscription.
              </h4>
              <h2 className='graybox-content'>
                {getPlanNameById(
                  userPlan?.value?.subscription_items[0].item_price_id,
                )}
              </h2>
            </div>
            <Divider className='graybox-divider' />

            <Button
              type={ButtonsType.Link}
              id='btn-plan-option'
              onClick={() => setVisibleLeaveSubscriptionModal(true)}
            >
              Leave current subscription
            </Button>
          </Card>
        </div>
      )}

      {userPlan.value && user.Type === UserTypes.Owner && (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            gap: 30,
          }}
        >
          <Card className='greybox plan-card-info' bordered={false}>
            <div>
              <h3 className='graybox-title'>Active Plan</h3>
              <h4 className='graybox-sub-title'>
                {userPlan.value.next_billing_at
                  ? `Next Payment: ${
                      userPlan.value.subscription_items[0].amount / 100
                    }  ${userPlan.value.currency_code} on ${formatDateString(
                      userPlan.value.next_billing_at,
                    )}`
                  : `Scheduled cancellation  at ${formatDateString(
                      userPlan.value.pause_date,
                    )}`}
              </h4>
              <br />
              <h2 className='graybox-content'>
                {getPlanNameById(
                  userPlan.value.subscription_items[0].item_price_id,
                )}
              </h2>
            </div>
            <Divider className='graybox-divider' />
            <Button
              type={ButtonsType.Link}
              id='btn-plan-option'
              onClick={() => setVisiblePlanModal(true)}
            >
              Edit Plan
            </Button>
            {userPlan.value.next_billing_at && (
              <Button
                type={ButtonsType.Link}
                id='btn-cancel-plan-option'
                onClick={() => setCancelPlanModal(true)}
              >
                Cancel Plan
              </Button>
            )}
          </Card>

          <Card className='greybox plan-card-info' bordered={false}>
            <div>
              <h3 className='graybox-title'>Concurrent Users</h3>
              <h4 className='graybox-sub-title'>
                Users online at the same time
              </h4>
              <br />
              <h2 className='graybox-content'>
                {userPlan.value.subscription_items[0].quantity}
              </h2>
            </div>
            <Divider className='graybox-divider' />
            <Button
              type={ButtonsType.Link}
              id='btn-uso-option'
              onClick={() => setVisiblePlanModal(true)}
            >
              Edit
            </Button>
          </Card>

          <Modal
            className='whitebox-centered'
            footer={[]}
            centered
            onCancel={() => setCancelPlanModal(false)}
            visible={cancelPlanModal}
          >
            <div className='whitebox-popup'>
              <div className='whitebox_Title'>
                <h2>Cancel Subscription?</h2>
              </div>
              <div className='centered-text' style={{ margin: '0' }}>
                <p>
                  Your subscription will be canceled at the end of the billing
                  period on {formatDateString(userPlan.value.next_billing_at)}.
                </p>
                <p>
                  You can always reactivate your subscription or downgrade to
                  free user.
                </p>
              </div>
              <div>
                <Button
                  className='ant-btn-streched'
                  type={ButtonsType.Primary}
                  onClick={() => handleCancelPlanClick()}
                >
                  Submit
                </Button>
              </div>
              <div>
                <Button
                  className='ant-btn-streched'
                  type={ButtonsType.Link}
                  onClick={() => setCancelPlanModal(false)}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      )}

      {user?.Type === UserTypes.Free && (
        <div>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              gap: 30,
            }}
          >
            <Card className='greybox plan-card-info' bordered={false}>
              <div>
                <h3 className='graybox-title'>Active Plan</h3>
                <h4 className='graybox-sub-title'>Forever free</h4>
                <h2 className='graybox-content'>Free</h2>
              </div>
              <Divider className='graybox-divider' />
              <Button
                type={ButtonsType.Link}
                id='btn-uso-option'
                onClick={() => setVisiblePlans(true)}
              >
                Upgrade plan
              </Button>
            </Card>
          </div>
        </div>
      )}
      <AskForMoreModal
        visibleAskForMoreModal={visibleAskForMoreModal}
        setVisibleAskForMoreModal={setVisibleAskForMoreModal}
      />
      <LeaveSubscriptionModal
        visibleLeaveSubscriptionModal={visibleLeaveSubscriptionModal}
        setVisibleLeaveSubscriptionModal={setVisibleLeaveSubscriptionModal}
      />
      <PlanModal
        visible={visiblePlanModal}
        setVisiblePlanModal={setVisiblePlanModal}
        setVisibleResources={setVisibleResources}
      />
    </div>
  );
}
