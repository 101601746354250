import React from 'react';

interface IconComponentProps {
  props: {
    iconName: string;
    style?: any;
    onClickHandle?: any;
    iconClassName?: string;
  };
}

export const IconComponent = ({ props }: IconComponentProps) => {
  return (
    <span
      className={`${props.iconName} ${props.iconClassName}`}
      style={props?.style}
      onClick={props?.onClickHandle}
    />
  );
};
