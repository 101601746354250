import { Progress } from 'antd';

import logo from '../../../assets/images/Vispa_Logo_137x156.svg';

const loadingScreenTexts = [
  'Charging the efficiency booster...',
  'Planting the seeds for success...',
  'Initializing areas...',
  'Mobilizing developers...',
  'Adding the 3rd dimension...',
  'Starting the innovation machine...',
];

const didYouKnowFacts = [
  'You can invite your team members directly to your space by clicking the share button inside your space',
  'You can change the environment color to create a different mood by clicking your space name and selecting a new color',
  'You can create break out sessions by accessing area edit mode and activating "sound area"',
  'You can change your avatar style by clicking on your name tag and accessing the Settings',
  'Go through our app tutorials to become a sensei in VISPA and get started quickly',
  'The participant list gives you a quick overview who is currently active in the space',
];

const fact =
  didYouKnowFacts[Math.floor(Math.random() * didYouKnowFacts.length)];

function getTextForPercent(percent) {
  if (percent > 90) return loadingScreenTexts[5];
  if (percent > 75) return loadingScreenTexts[4];
  if (percent > 50) return loadingScreenTexts[3];
  if (percent > 35) return loadingScreenTexts[2];
  if (percent > 15) return loadingScreenTexts[1];
  return loadingScreenTexts[0];
}

export function LoadingComponent({ props }) {
  const percent = Math.round(props.loadingProgression * 100);

  return (
    <div>
      <div className='loading'>
        <img
          style={{
            margin: '0 5rem 60px',
            width: 137,
            height: 156,
          }}
          src={logo}
          alt='logo'
        />
        <p
          style={{
            fontSize: 20,
            fontWeight: 700,
            marginBottom: 15,
            lineHeight: 1.4,
          }}
        >
          {getTextForPercent(percent)} {percent}%
        </p>
        <Progress
          style={{ maxWidth: 500 }}
          showInfo={false}
          strokeColor={{
            '0%': '#606ee0',
            '100%': '#a356d2',
          }}
          percent={Math.round(props.loadingProgression * 100)}
        />
        <p
          style={{
            marginTop: 50,
            fontSize: 18,
            fontWeight: 600,
            maxWidth: 500,
            lineHeight: 1.4,
          }}
        >
          {fact}
        </p>
      </div>
    </div>
  );
}
