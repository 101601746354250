import { Button, Col, Form, Input, Modal, Row, Select, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ORG_MEMBERS } from '../../../../graphql/get-org-members.query';
import { Loading } from '../../../../components/Loading/Loading';
import {
  CHANGE_MEMBER_TYPE,
  DELETE_ORG_MEMBERS,
} from '../../../../graphql/delete-org-member.mutation';
import { IconComponent } from '../../../../components/Icon/Icon';
import { GET_COMPANY } from '../../../../graphql/get-company.query';
import { ButtonsType } from '../../../../shared/enums/buttons-type.enum';
import { useInviteMembersMutation } from '../../../Api/org-members';
import { AvailableSoon } from '../../../../components/AvailableSoon/AvailableSoon';
import { useAppSelector } from '../../../../app/hooks';
import { selectUser, UserTypes } from '../../../Profile/userSlice';
import { UserTypesColor } from '../../../Profile/userSlice';
import {
  getIdTokenSync,
  getUserData,
} from '../../../Api/helpers/get-user-data';

const { Option } = Select;

interface invitesData {
  duplicateSubscriptionCurrent: string[];
  duplicateSubscriptionOther: string[];
  success: string[];
}

export function Members() {
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [usersDataSource, setUsersDataSource] = useState<any[]>([]);
  const [inviteModalVisible, setInviteModalVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [organization, setOrganization] = useState<any>(null);

  const [invitesData, setInvitesData] = useState<invitesData>();

  const organizationMembersQuery = useQuery(GET_ORG_MEMBERS);
  const organizationQuery = useQuery(GET_COMPANY);
  const [inviteMembers] = useInviteMembersMutation();
  const [handleDeleteOrgMembersFunction] = useMutation(DELETE_ORG_MEMBERS);
  const [handleChangeUserType] = useMutation(CHANGE_MEMBER_TYPE);
  const [inviteForm] = Form.useForm();

  const user = useAppSelector(selectUser);

  useEffect(() => {
    onComponentInit();
  }, []);

  function handleCloseModal() {
    setInvitesData(null);
    setInviteModalVisible(false);
  }

  const onComponentInit = async () => {
    try {
      const organizationResponse = await organizationQuery.refetch({
        adminId: getUserData().id,
      });
      if (!organizationResponse?.data?.OrganizationMembers[0]?.Organization)
        return;
      const organization =
        organizationResponse?.data?.OrganizationMembers[0]?.Organization;
      setOrganization(organization);
      if (!organization?.Id) return;
      const organizationMembersResponse =
        await organizationMembersQuery.refetch({ orgId: organization?.Id });
      const usersData = [];

      for (let member of organizationMembersResponse.data.OrganizationMembers) {
        if (!member.User) continue;
        usersData.push({
          key: member.User.Id,
          lastname: member.User.FamilyName,
          firstname: member.User.GivenName,
          email: member.User.Email,
          role: {
            value: member.Type,
            label: (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: 220,
                  color: UserTypesColor.get(member.Type),
                }}
              >
                <span>Subscription {member.Type}</span>
                <span style={{ verticalAlign: 'bottom' }}></span>
              </div>
            ),
          },
        });
      }
      setUsersDataSource(usersData);
    } catch (e) {
      console.log(e);
    }
  };

  const handleDeleteOrgMembers = async (record) => {
    if (!organization?.Id) return;
    const userIds = record?.key ? [record.key] : selectedRowKeys;
    if (!userIds.length) return;
    await handleDeleteOrgMembersFunction({
      variables: { userIds, orgId: organization.Id },
    });
    await onComponentInit();
  };

  async function handleRoleChange(role, user) {
    await handleChangeUserType({
      variables: { userId: user.key, type: role },
    });
    await onComponentInit();
  }

  const handleInvitingMembers = async () => {
    inviteForm
      .validateFields()
      .then(async ({ emails, role, message }) => {
        setConfirmLoading(true);

        inviteMembers({
          emails,
          type: role,
          orgId: organization.Id,
          message,
          token: getIdTokenSync(),
        })
          .then(async (resolved: any) => {
            inviteForm.resetFields();
            setConfirmLoading(false);

            if (resolved?.data?.length !== 0) {
              setInvitesData(resolved.data);
              await onComponentInit();
              return;
            }
          })
          .catch((rejected) => {
            console.error(rejected);
            onComponentInit();
          });
      })
      .catch((info) => {
        setConfirmLoading(false);
        console.error('Validate Failed:', info);
      })
      .finally(async () => {
        await onComponentInit();
      });
  };

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = { selectedRowKeys, onChange: onSelectChange };

  // @ts-ignore
  const columns: any = [
    { title: 'Firstname', dataIndex: 'firstname' },
    { title: 'Lastname', dataIndex: 'lastname' },
    { title: 'Email', dataIndex: 'email' },
    {
      title: 'Role',
      dataIndex: 'role',
      render: (_, record) => (
        <Select
          showArrow={true}
          bordered={false}
          style={{ border: 'none', borderStyle: 'none' }}
          onChange={(role) => handleRoleChange(role, record)}
          defaultValue={record.role.label}
          disabled={record.role.value === UserTypes.Owner}
          options={[
            {
              value: UserTypes.Member,
              label: (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: 220,
                    color: UserTypesColor.get(UserTypes.Member),
                  }}
                >
                  <span>Subscription Member</span>
                  <span
                    style={{ verticalAlign: 'bottom', visibility: 'hidden' }}
                  ></span>
                </div>
              ),
            },
            {
              value: UserTypes.Admin,
              label: (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: 220,
                    color: UserTypesColor.get(UserTypes.Admin),
                  }}
                >
                  <span>Subscription Admin</span>
                  <span
                    style={{ verticalAlign: 'bottom', visibility: 'hidden' }}
                  ></span>
                </div>
              ),
            },
          ]}
        />
      ),
    },
    {
      title: (
        <IconComponent
          props={{
            iconName: 'icon-Trash',
            onClickHandle: handleDeleteOrgMembers,
          }}
        />
      ),
      render: (_, record) =>
        record.role.value !== UserTypes.Owner && (
          <div style={{ cursor: 'pointer' }}>
            <IconComponent
              props={{
                iconName: 'icon-Close',
                onClickHandle: () => handleDeleteOrgMembers(record),
              }}
            />
          </div>
        ),
    },
  ];

  if (user?.Type === UserTypes.Admin || user?.Type === UserTypes.Owner) {
    //
  } else {
    return;
  }
  return (
    <div id='members' className='whitebox'>
      {organizationQuery.loading && <Loading />}

      <div>
        <Row>
          <Col span={18}>
            <h2>Members</h2>
            <p>Anyone who participates in your subscription</p>
          </Col>
          <Row id='inviteButton_Row'>
            <Button
              type={ButtonsType.Primary}
              onClick={() => setInviteModalVisible(!inviteModalVisible)}
            >
              Invite members
            </Button>
            <AvailableSoon
              componentToPassDown={
                <Button type={ButtonsType.Default} disabled={true}>
                  Import CSV
                </Button>
              }
            />
            <AvailableSoon
              componentToPassDown={
                <Button type={ButtonsType.Default} disabled={true}>
                  Example CSV
                </Button>
              }
            />
          </Row>
        </Row>
      </div>

      <Table
        className='subscription-members-table'
        rowSelection={rowSelection}
        columns={columns}
        dataSource={usersDataSource}
      ></Table>

      <Modal
        className='whitebox-centered'
        visible={inviteModalVisible}
        onOk={handleInvitingMembers}
        onCancel={() => handleCloseModal()}
        confirmLoading={confirmLoading}
      >
        <div className='whitebox-popup'>
          <div className='whitebox_Title'>
            <h2>Invite New Members</h2>
          </div>
          <p className='centered-text'>
            You can invite as many users as you want. You just need to watch the
            number of your concurrent users
          </p>

          <Form
            form={inviteForm}
            layout='vertical'
            name='form_in_modal'
            initialValues={{ role: UserTypes.Member }}
          >
            <Form.Item
              name='emails'
              label='Emails'
              rules={[
                {
                  required: true,
                  message: 'Enter one or more email addresses',
                },
              ]}
            >
              <Input.TextArea placeholder='example1@email.com, example2@email.com' />
            </Form.Item>

            <Form.Item name='message'>
              <Input placeholder='(Optional) Add your own message' />
            </Form.Item>

            {invitesData?.success?.length > 0 && (
              <p style={{ color: 'green' }}>
                <IconComponent
                  props={{
                    iconName: 'icon-Done_Circle',
                    style: { fontSize: 15 },
                  }}
                />
                The following email(s) have been added to your
                subscription:&nbsp;
                {invitesData.success.map((email: string) =>
                  email !==
                  invitesData.success[invitesData.success.length - 1] ? (
                    <span>{email},&nbsp;</span>
                  ) : (
                    <span>{email}</span>
                  ),
                )}
              </p>
            )}

            {invitesData?.duplicateSubscriptionOther?.length > 0 && (
              <p style={{ color: 'red' }}>
                <IconComponent
                  props={{
                    iconName: 'icon-Error_Circle',
                    style: { fontSize: 15 },
                  }}
                />
                This email is already a part of another subscription:&nbsp;
                {invitesData?.duplicateSubscriptionOther.map((e) =>
                  e !==
                  invitesData.duplicateSubscriptionOther[
                    invitesData.duplicateSubscriptionOther.length - 1
                  ] ? (
                    <span>{e},&nbsp;</span>
                  ) : (
                    <span>{e}</span>
                  ),
                )}
              </p>
            )}

            {invitesData?.duplicateSubscriptionCurrent?.length > 0 && (
              <p style={{ color: 'red' }}>
                <IconComponent
                  props={{
                    iconName: 'icon-Error_Circle',
                    style: { fontSize: 15 },
                  }}
                />
                This email has already been added to your subscription:&nbsp;
                {invitesData?.duplicateSubscriptionCurrent.map((e) =>
                  e !==
                  invitesData.duplicateSubscriptionCurrent[
                    invitesData.duplicateSubscriptionCurrent.length - 1
                  ] ? (
                    <span>{e},&nbsp;</span>
                  ) : (
                    <span>{e}</span>
                  ),
                )}
              </p>
            )}
            <Form.Item name='role' label='Role'>
              <Select>
                <Option value={UserTypes.Admin}>Admin</Option>
                <Option value={UserTypes.Member}>Member</Option>
              </Select>
            </Form.Item>

            <div>
              <Button
                className='ant-btn-streched'
                key='submit'
                type={ButtonsType.Primary}
                onClick={handleInvitingMembers}
                loading={confirmLoading}
              >
                Invite
              </Button>
            </div>
            <div>
              <Button
                className='ant-btn-streched'
                key='back'
                type={ButtonsType.Link}
                onClick={() => setInviteModalVisible(false)}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
}
