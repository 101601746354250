import React from 'react';
import { Button } from 'antd';
import { useMutation } from '@apollo/client';
import { TRACK_EVENT } from '../../graphql/track-event.mutation';
import { vispaBuild, vispaMacBuild } from '../../config/env';
import { getUserData } from '../Api/helpers/get-user-data';
import WindowsSvg from '../../assets/icons/Windows-logo.svg';
import BrowserSvg from '../../assets/icons/Browser-logo.svg';
import AppleSvg from '../../assets/icons/Apple-logo.svg';

const vispaContactUrl =
  'https://meetings.hubspot.com/manuel176/discovery-call-enterprise?hsCtaTracking=296eacf1-2ed6-4311-b2dd-53b9477ba81d%7C241ea857-2071-45dc-9be6-19d27cd7622d';

export function Download() {
  const [trackEvent] = useMutation(TRACK_EVENT);

  const handleDownloadWindows = async () => {
    await trackEvent({
      variables: {
        userId: getUserData().id,
        category: 'Download',
        action: 'Windows clicked',
      },
    });
  };

  return (
    <div>
      <div className='whitebox'>
        <h2>Get VISPA</h2>
        <div className='lightgrey-subheadline'>
          Download VISPA for Windows/Mac or start directly in your Browser.
        </div>
        <table id='download-btns'>
          <td className='greybox-download'>
            <div className='greybox-download-title'>VISPA for Windows</div>
            <div className='greybox-download-circle'>
              <img className='greybox-download-circle-icon' src={WindowsSvg} />
            </div>
            <Button
              onClick={handleDownloadWindows}
              className='download-btn'
              type='primary'
            >
              <a href={vispaBuild}>Download for Windows</a>
            </Button>
          </td>
          <td className='greybox-download'>
            <div className='greybox-download-title'>VISPA for Web (BETA)</div>
            <div className='greybox-download-circle'>
              <img className='greybox-download-circle-icon' src={BrowserSvg} />
            </div>
            <Button
              onClick={handleDownloadWindows}
              className='download-btn'
              type='primary'
            >
              <a target='_blank' href='/app'>
                Start in your Browser
              </a>
            </Button>
          </td>
        </table>
        <div className='greybox'>
          <h3>Windows</h3>
          <ol>
            <li>Save the .zip file to your desktop</li>
            <li>
              To unpack the .zip file, right click on it and select “Extract
              all”
            </li>
            <li>
              To start VISPA, open the unpacked folder and open the .exe file
            </li>
            <li>
              A warning may appear the first time you start the app. Click "More
              info" and "Run anyway"
            </li>
            <li>Please do not change any of the files in this folder</li>
            <li>Repeat step 3 to open VISPA in the future</li>
          </ol>
        </div>
        <br />
        {/*<div className='greybox'>*/}
        {/*  <h3>MacOS</h3>*/}

        {/*  <ol>*/}
        {/*    <li>*/}
        {/*      Click the "Download for MacOS"-Button at the top, which will link*/}
        {/*      to the App Store*/}
        {/*    </li>*/}
        {/*    <li>*/}
        {/*      Inside the App Store click on the "Get"-Button and click it one*/}
        {/*      more to install VISPA*/}
        {/*    </li>*/}
        {/*    <li>*/}
        {/*      After installing, VISPA will be added to your Mac and can be*/}
        {/*      started through the Launchpad*/}
        {/*    </li>*/}
        {/*  </ol>*/}
        {/*</div>*/}
        <br />

        <br />

        <div>
          <b>
            If you have any questions about how to download or use VISPA, do not
            hesitate to contact us
          </b>
        </div>
        <br />
        <Button type='primary'>
          <a target='_blank' href={vispaContactUrl}>
            Contact us
          </a>
        </Button>
      </div>

      <div className='whitebox'>
        <h2>Minimum technical requirements</h2>
        <div className='lightgrey-subheadline'>
          What specs does your machine need to use VISPA
        </div>
        <div className='greybox'>
          <h3 style={{ marginLeft: 10 }}>Windows</h3>
          <ul>
            <li>Please use broadband connection</li>
            <li>Windows 7 (SP1+), Windows 10 and Windows 11</li>
            <li>4 GB RAM</li>
            <li>
              NVIDIA GTX 460, ATI Radeon HD 4850, Intel HD Graphics 550 or
              similar (DX10, DX11, D12 capable)
            </li>
          </ul>
        </div>

        {/*<div style={{ marginTop: 25 }} className='greybox'>*/}
        {/*  <h3 style={{ marginLeft: 10 }}>Mac</h3>*/}
        {/*  <ul>*/}
        {/*    <li>Please use broadband connection</li>*/}
        {/*    <li>High Sierra 10.13+</li>*/}
        {/*    <li>8 GB RAM</li>*/}
        {/*    <li>Metal capable Intel and AMD GPUs</li>*/}
        {/*  </ul>*/}
        {/*</div>*/}
        <br />

        <div style={{ marginTop: 5 }} className='greybox'>
          <p>
            <b>If connecting is not possible</b>
          </p>
          <div>
            <ul>
              <li>Turn off your company’s VPN and use the guest WiFi</li>
              <li>With a mobile WiFi hotspot, turn off your phones VPN</li>
            </ul>
          </div>
        </div>

        <div style={{ marginTop: 15 }}>
          <b>
            If you have any questions about how to download or use VISPA, do not
            hesitate to contact us
          </b>

          <br />

          <Button type='primary'>
            <a target='_blank' href={vispaContactUrl}>
              Contact us
            </a>
          </Button>
        </div>
      </div>
    </div>
  );
}
