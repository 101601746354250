import React, { useState } from 'react';
import { Button, Drawer } from 'antd';
import './Navbar.css';
import logo from '../../../assets/images/VISPA_Logo_143x40.svg';
import { Auth } from 'aws-amplify';
import { IconComponent } from '../../../components/Icon/Icon';
import { useAppSelector } from '../../../app/hooks';
import { selectApp } from '../../App/appSlice';

export const NavBar = ({ loggedIn = false, setScreen, menu }) => {
  const [visible, setVisible] = useState(false);
  const app = useAppSelector(selectApp);

  if (app.hideSidebar) return;

  return (
    <div className='navbar-wrapper'>
      <nav className='navbar'>
        <a href='/'>
          <img src={logo} className='logo' alt='logo' />
        </a>

        {loggedIn && (
          <div style={{ float: 'right' }}>
            <Button
              className='menu'
              type='link'
              icon={
                <IconComponent
                  props={{ iconName: 'icon-Menu', style: { fontSize: 30 } }}
                />
              }
              onClick={() => setVisible(true)}
            />
            <Drawer
              title='Topics'
              placement='right'
              onClick={() => setVisible(false)}
              onClose={() => setVisible(false)}
              visible={visible}
            >
              {menu}
            </Drawer>
            <Button
              id='logout-btn'
              style={{ marginTop: 0 }}
              type='link'
              icon={
                <IconComponent
                  props={{ iconName: 'icon-Logout', style: { fontSize: 30 } }}
                />
              }
              onClick={async () => {
                window.history.pushState({}, document.title, '/');
                await Auth.signOut();
                await window.location.reload();
              }}
            >
              <span id='logout-btn-text'>Logout</span>
            </Button>
          </div>
        )}
      </nav>
    </div>
  );
};
